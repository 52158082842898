import React from 'react';
import MobileBanner from "@/page-components/home/Banner/mobile_banner";
import PCBanner from "@/page-components/home/Banner/pc_banner";
import {TUserInfoTypes} from "@/types/redux_type";
import MobileComponentWrapper from "@/components/MobileComponentWrapper";
import PcComponentWrapper from "@/components/PcComponentWrapper";
import styles from './styles.module.less';

type TBanner = {
  userInfo:TUserInfoTypes
}

const Banner:React.FC<TBanner> = ({userInfo}) => {
  return (
    <div className={styles.banner_wrapper}>
      <MobileComponentWrapper>
        <MobileBanner />
      </MobileComponentWrapper>
      <PcComponentWrapper>
        <PCBanner isTeacher={userInfo.isTeacher}/>
      </PcComponentWrapper>
    </div>
  )
}
export default Banner;
