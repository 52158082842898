import React from "react";
import {Button, Tooltip} from "antd";
import { Link } from 'react-scroll'
import Panel from "@/page-components/home/Fast_nav/panel";
import styles from './styles.module.less';

type TFastNav = {
  left: number;
}

const FastNav:React.FC<TFastNav> = ({left}) => {
  return (
    <>
      <div className={styles.fast_nav_wrapper} style={{left: `${left}px`}}>
        <Link activeClass={styles.active} to="test3" spy smooth duration={500} >
          <div className={styles.item}>
            认证体系
          </div>
        </Link>
        <Link activeClass={styles.active} to="test4" spy smooth duration={500} >
          <div className={styles.item}>
            数据人才
          </div>
        </Link>
        <Link activeClass={styles.active} to="test5" spy smooth duration={500} >
          <div className={styles.item}>
            企业评价
          </div>
        </Link>
        <Link activeClass={styles.active} to="test6" spy smooth duration={500} >
          <div className={styles.item}>
            常见问题
          </div>
        </Link>
        <Link activeClass={styles.active} to="test7" spy smooth duration={500} >
          <div className={styles.item}>
            推荐阅读
          </div>
        </Link>
      </div>
      <Tooltip placement="right" title={Panel} color="white">
        <Button type="primary" className={styles.ask_btn} style={{left: `${left}px`}}>
          <img src="/img/ico_chat.png" width="16" height="16" alt="kefu" />
          <p className={styles.icon_text}>在线咨询</p>
        </Button>
      </Tooltip>
    </>
  )
}
export default FastNav;
