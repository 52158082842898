import React from 'react';
import PcComponentWrapper from '@/components/PcComponentWrapper';
import PCScroll from '@/page-components/home/Company/pc_scroll';
import MobileComponentWrapper from '@/components/MobileComponentWrapper';
import styles from './styles.module.less';

const Company: React.FC = () => {
  const data = [
    {
      name: '惠科金渝 信息管理处处长 吕劼',
      url: 'img/index_logo/company0.png',
      text: '我们会把它当做其中的一个招人标准，那对于我们来说，我今天去招这些人才的时候，我们也有一个非常客观的标准，能够得到一个很真实的信息。',
    },
    {
      name: '新兴铸管股份有限公司 技术主管 米浩',
      url: 'img/index_logo/company1.png',
      text: '我司早在2019年就组织技术人员参加帆软的报表工程师认证考试，为鼓励考生积极备考，公司报销报名费用及相关的培训投入，最终有两名同事通过帆软FR的资深认证。',
    },
    {
      name: '广州硅能照明有限公司 体系经理 孙婷',
      url: 'img/index_logo/company2.png',
      text: '帆软认证对于我们企业来说是筛选数据人才的标准之一，通过帆软认证证明该人员已经掌握数据分析的基本工具，能够匹配岗位技能要求。',
    },
    {
      name: '青岛如是书店 首席数字官CDO 于添翼',
      url: 'img/index_logo/company3.png',
      text: '我们在2019年了解到了帆软职业资格认证，目前帆软认证已作为我们如是书店DT团队招募和培训伙伴的必要标准和参考。',
    },
    {
      name: '浙江银轮机械股份有限公司 信息化负责人 杨杰',
      url: 'img/index_logo/company4.png',
      text: '帆软的职业资格认证对于企业来说是难得的衡量开发人员技术能力的公平标准，综合评估开发人员对于帆软报表开发的能力水平，我们公司已经要求新入职员工必须在1个月内通过高级认证。',
    },
    {
      name: '福建升腾资讯有限公司 流程与信息管部副总监 陈凤鸣',
      url: 'img/index_logo/company5.png',
      text: '团队成员通过数据运营官、帆软报表工程师、BI工程师的培训认证，快速掌握了数据化转型的方法论、数据展示工具。目前认证已作为团队内数据运维部门新员工入职、激励老员工、团队主管主动学习以便升职加薪必要条件。',
    },
  ];
  return (
    <div className={styles.company}>
      <h3 className={styles.title}>企业评价</h3>
      <h3 className={styles.subtitle}>
        减少招聘方筛选简历成本，帮助企业识别优秀人才
      </h3>
      <div className={styles.tab_img_wrapper}>
        <video
          poster="/img/video_poster.png"
          controls
          className={styles.video_area}
          src="https://bbs.fanruan.com/wxapp_img/index_video.m4v"
        >
          <track />
        </video>
        <PcComponentWrapper>
          <PCScroll data={data} />
        </PcComponentWrapper>
        <MobileComponentWrapper>
          <div className={styles.text_area}>
            {data.map((item, index) => {
              return (
                <div className={styles.text_body} key={Math.random()}>
                  <div className={styles.text}>{item.text}</div>
                  <div className={styles.company_name}>{item.name}</div>
                </div>
              );
            })}
          </div>
        </MobileComponentWrapper>
      </div>
    </div>
  );
};
export default Company;
