import {Popover} from 'antd';
import React, {useContext} from 'react';
import HeaderDrawer from '@/components/NavigationHeader/HeaderDrawer';
import HeaderUserPopover from '@/components/NavigationHeader/HeaderUserPopover';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import {userAvatar} from '@/utils/index';

const HeaderUser: React.FC = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {uid, device} = NavigationHeaderContextProps;
  return (
    <div>
      {device === 'pc' ? (
        <Popover
          overlayClassName="dr-nav-popover"
          content={<HeaderUserPopover/>}
          placement="bottomRight"
        >
          <img alt="" className="dr-nav-avatar" src={userAvatar(uid as string)}/>
        </Popover>
      ) : (
        <HeaderDrawer
          placement="right"
          button={
            <img className="dr-nav-avatar" src={userAvatar(uid as string)}/>
          }
        >
          <HeaderUserPopover/>
        </HeaderDrawer>
      )}
    </div>
  );
};
export default HeaderUser;
