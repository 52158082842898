import React from "react";
import {NavigationFooter} from "@digit-fe/digit-component";
import PcComponentWrapper from "@/components/PcComponentWrapper";
import PcFooter from "@/layouts/Footer/pcFooter";
import MobileComponentWrapper from "@/components/MobileComponentWrapper";
import MobileFooter from "@/layouts/Footer/mobileFooter";

type TFooter = {
  className?: string;
  havePcFooter?: boolean;
  haveMobileFooter?: boolean;
}

const Footer:React.FC<TFooter> =({havePcFooter=true,haveMobileFooter=true,className}) => {
  return (
    <>
      <NavigationFooter />
    </>
  )
}
export default Footer;
