import {removeAllCookie} from "@/utils";
import {TUserInfoTypes} from "@/types/redux_type";
import {CLEARCOOKIE, SETUSERINFO, LOGOUT} from '@/actions/types'

const initialState:TUserInfoTypes | object = {
  init: false,// 是否已经进行了获取用户数据的操作
};

const authReducers = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case LOGOUT:
      return {init: false};
    case SETUSERINFO:
      console.log('SETUSERINFO被触发');
      console.log(action.payload);
      return {...state,...action.payload, init: true}
    case CLEARCOOKIE:
      removeAllCookie();
      return {...state, uid: undefined, username: undefined}
    default:
      return state;
  }
};
export default authReducers;
