type TFineClubModuleOptions = {
  getLoginUid: () => number;
  monitorIframe: Node;
  monitorUrl: string,
  container: string,
  app: string,
  debug: boolean
}

class FineClubModule {
  private monitorUrl = ""; // 监听的地址
  private container = "";
  private app = "";
  private debug = true;
  private monitorIframe?: Node = undefined;
  private getLoginUid?: () => number = undefined;

  constructor(initParams: Partial<TFineClubModuleOptions>) {
    const {
      monitorUrl=`${process.env.NEXT_PUBLIC_PASSPORT_URL}login/cas/monitor`,
      container="body",
      app="cert",
      debug=true,
      monitorIframe,
      getLoginUid
    } = initParams;
    this.monitorUrl = monitorUrl;
    this.container = container;
    this.app = app;
    this.debug = debug;
    this.monitorIframe = monitorIframe;
    this.getLoginUid = getLoginUid;
  }

  private getSelector = () => {
    return document.querySelector(this.container);
  }
  private removeMonitorIframe = () => {
    if (this.monitorIframe) {
      try {
        const selector = this.getSelector();
        if (selector) {
          selector.removeChild(this.monitorIframe)
        }
      } catch (e) {
        if (this.debug) {
          console.log(e);
        }
      }
      this.monitorIframe = undefined;
    }
  };
  private injectMonitorIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.src = this.monitorUrl;
    iframe.style.display = 'none';
    this.monitorIframe = iframe;
    const selector = document.querySelector(this.container);
    if (selector) {
      selector.appendChild(iframe);
    }
  };
  private wrapSingleTabCallback = (callback: (arg0: any) => void) => (value: any) => {
    // 确保只有一个标签页处理了登录请求.
    // 如果打开多个标签页,每个标签页取一个随机数作为唯一ID,写入localStorage,最后取出最终的保留的ID作为唯一处理者
    const key = JSON.stringify(value) + Math.floor(Math.random() * 1000);
    window.localStorage.setItem('login_trigger_handler', key);
    setTimeout(() => {
      const handledKey = window.localStorage.getItem('login_trigger_handler');
      if (key === handledKey) {
        callback(value);
      } else if (this.debug) {
        console.log('已在其他标签页响应本次登录态,跳过');
      }
    }, 250);
  };
  public onLoginChange = (callback: (arg0: any) => void) => {

    let cancel = false;
    const {origin} = new URL(this.monitorUrl);
    const wrappedCallback = this.wrapSingleTabCallback(callback);
    const onMessage = (e: { origin: string; data: any }) => {
      if (cancel) {
        return;
      }
      if (this.debug) {
        console.log('onMessage', e);
      }
      if (e.origin !== origin) {
        return;
      }
      const {data} = e;
      const {type} = data;
      const value = data.data;
      if (type === 'loginState') {

        if (!this.getLoginUid) {
          if (this.debug) {
            console.log('没有配置getLoginUid,无法判断当前登录用户是否一致,跳过');
          }

          return;
        }

        // 初始登录状态
        if (value.uid) {
          const uid = this.getLoginUid();
          if (uid !== value.uid) {
            if (this.debug) {
              console.log('登录用户不一致,重新发起单点登录');
            }
            wrappedCallback(value);
          }
        }
        // else {
        //     // 通知前端登出 这个不应被在这里实现 因为跨域问题也拿不到uid
        //     // callback({ uid: 0 });
        // }
      } else if (type === 'loginChange') {
        if (this.debug) {
          console.log(`通行证登录用户为${value.username}(${value.uid})`);
        }
        if (value.app && this.app && value.app === this.app) {
          // 忽略当前APP自己触发的登录
          if (this.debug) {
            console.log('当前应用触发的登录态变更,跳过');
          }

          return;
        }
        if (this.debug) {
          if (value.uid) {
            console.log(`应用${value.app}触发了登录态变化,新的登录用户为${value.username}(${value.uid})`);
          } else {
            console.log('其他应用触发了登出');
          }
        }
        wrappedCallback(value);
      }

      // 一定时间后自动移除登录iframe,这期间应该完成了自动单点
      // setTimeout(() => {
      //     try {
      //         if (debug) {
      //             console.log('已移除自动登录iframe');
      //         }
      //         removeMonitorIframe();
      //     } catch (e) {
      //         console.error(e);
      //     }
      // }, 5000);
    };
    window.addEventListener('message', onMessage, false);
    this.injectMonitorIframe();

    return () => {
      cancel = true;
      window.removeEventListener('message', onMessage);
      this.removeMonitorIframe();
    };
  };
}

export default FineClubModule;
