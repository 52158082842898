import React from "react";
import {Space} from "antd";
import styles from './styles.module.less';

type TQaItem = {
  number: number,
  question: string;
  answer: string;
}
const QaItem:React.FC<TQaItem> =({number,question,answer}) => {
  return (
    <Space className={styles.qa_wrapper_body_item} size={16}>
      <p className={styles.item_num}>Q{number}</p>
      <div>
        <div className={styles.item_title}>{question}</div>
        <p className={styles.item_text} dangerouslySetInnerHTML={{__html: answer}} />
      </div>
    </Space>
  )
}
export default QaItem
