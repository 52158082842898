import React, {useState} from "react";
import {Skeleton} from 'antd';
import cx from "classnames";
import {useRequest} from "ahooks";
import moment from "moment";
import {apiGetJobList} from "@/services/job";
import {TExamKind} from "@/types";
import ExamArrow from "@/components/ui/exam_arrow";
import styles from './styles.module.less';

type TJobList = {
  type?: TExamKind;
  className?: string;
}

type JobData = {
  jid: number;
  comname:string;
  monthsalary:string;
  jobname:string;
  province:string;
  city: string;
  jobnature:string;
  etime: number;
  jobnum: number;
  jobvacancy:number;
}

enum myrenzheng {
  'FCRP'=1,
  'FCBP',
  'FCBP6',
  'FCAP',
  'FCRA',
  'FCBA',
  'FCBA5'=5,
  'FCAA',
  'FCJA'=8,
  'FCRP-D'=10,
}

const jobTypeMapping:any = {
  'fcra':1,
  'fcrp':1,
  'fcrpd':10,
  'fcba':2,
  'fcbp':2,
  'fcbp6':2,
  'fcaa':3,
  'fcap':3,
  'fcja':5,
  'fcjp':5,
  'fca_shuzhiniao':3,
  'fcp_plugin':1,
  'fca_data_analysis':1,
  'fcba5':2,
}

const JobList:React.FC<TJobList> = ({type,className}) => {
  const [data, setData] = useState<JobData[]>([]);
  const {loading} = useRequest(() => {
    const getJob = () => {
      console.log('type是');
      console.log(type);
      if (type && jobTypeMapping[type]) {
        return jobTypeMapping[type];
      }
      return '';
    }
    const job = getJob();
    if (job){
      return apiGetJobList({job}) as Promise<{code: number;data:JobData[]}>
    }
    return apiGetJobList({}) as Promise<{code: number;data:JobData[]}>
  },{
    refreshDeps:[type],
    onSuccess:(res) => {
      if (res?.code === 200){
        setData(res.data);
      }
    }
  })
  const renderText = (text: string) => {
    switch (text){
      case 'FCRP':return 'FCP-报表开发工程师';
      case 'FCRP-D':
      case 'FCRPD':return 'FCP-报表交付工程师';
      case 'FCBP':return 'FCP-FineBI';
      case 'FCJA':
      case 'FCJP':return 'FCP-零代码开发工程师';
      case 'FCAP':return 'FCP-业务分析师';
      default:return text;
    }
  }
  const renderLink = () => {
    switch (type) {
      case "fcrpd":
      case "fcrp":
      case "fcra":return '/jobs-0-1-0-0-0';
      case "fcba":
      case "fcbp":return '/jobs-0-2-0-0-0';
      case "fcaa":
      case "fcap":return '/jobs-0-3-0-0-0';
      case "fcja":
      case "fcjp":return '/jobs-0-5-0-0-0';
      default:return '/jobs';
    }
  }
  return (
    <div className={cx(styles.job_area_wrapper,className)}>
      <p className={styles.job_area_wrapper_title}>企业招聘</p>
      {loading ? <Skeleton /> : data.map(item => {
        return (
          <a rel="noreferrer" href={`https://bbs.fanruan.com/jobs/${item.jid}`} key={item.jid} className={styles.job_area_wrapper_item} target="_blank">
            <div className={styles.job_area_wrapper_item_row}>
              <p className={styles.job_info_title}>{item.comname}</p>
              <p className={cx(styles.job_info_title,styles.color_orange)}>{item.monthsalary}</p>
            </div>
            <div className={styles.job_area_wrapper_item_row}>
              <p className={styles.job_info_subtitle}>{item.jobname}</p>
              <p className={cx(styles.job_info_subtitle)}>{item.city} / {item.jobnature} / 招{item.jobnum}人</p>
            </div>
            <div className={styles.job_area_wrapper_item_row}>
              {myrenzheng[item.jobvacancy] && (
                <div className={styles.job_info_tag}>
                  {/*{item.jobvacancy}*/}
                  {renderText(myrenzheng[item.jobvacancy])}优先
                </div>
              )}
              <p className={cx(styles.job_info_time)}>{moment(item.etime * 1000).from(moment())}更新</p>
            </div>
          </a>
        )
      })}
      <a rel="noreferrer" className={styles.job_area_wrapper_footer} target="_blank" href={`https://bbs.fanruan.com${renderLink()}`}>
        查看更多在招岗位
        <ExamArrow />
      </a>
    </div>
  )
}
export default JobList
