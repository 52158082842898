import {StandardProps} from '@/types/component';
import '@/components/DivisionLine/styles/index.less';
import {Space} from 'antd';
import cx from 'classnames';
import React, {type FC} from 'react';

interface IDivisionLineProps extends StandardProps {
  divisionCom?: React.ReactElement;
  gap?: number;
  lineClassName?: string;
}

const DivisionLine: FC<IDivisionLineProps> = ({
                                                lineClassName,
                                                children,
                                                gap,
                                                divisionCom,
                                              }) => {
  const countChildren = () => {
    const childFilter: (
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
      | undefined
      )[] = [];
    React.Children.forEach(children, (Child) => {
      if (Child !== null) {
        childFilter.push(Child);
      }
    });
    return childFilter;
  };
  const childrenFilter = countChildren();
  const divisionComponent = divisionCom || (
    <div
      className={cx('dr-division-line', lineClassName)}
      style={{margin: gap ? `0 ${gap}px` : '0 20px'}}
    />
  );
  return (
    <div className="dr-division">
      <Space size={0}>
        {childrenFilter &&
          React.Children.map(childrenFilter, (Child, index) => {
            return (
              <Space size={0} key={Math.random()}>
                {Child}
                {index !== childrenFilter.length - 1 && divisionComponent}
              </Space>
            );
          })}
      </Space>
    </div>
  );
};
export default DivisionLine;
export type {IDivisionLineProps};
