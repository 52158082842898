import React, {CSSProperties} from "react";
import {Media} from "@/constants/media_query";

type TMobileComponentWrapper = {
  className?: string;
  style?: CSSProperties;
}

const PhoneComponentWrapper:React.FC<TMobileComponentWrapper> = (props) => {
  return (
    <Media lessThan="md" className={props.className}><div style={props.style}>{props.children}</div></Media>
  )
}
export default PhoneComponentWrapper;
