import React from "react";
import cx from 'classnames';
import {TExamCategory} from "@/types";
import styles from './styles.module.less';

type TTag = {
  type: TExamCategory
}

const Tag:React.FC<TTag> = ({type}) => {
  return (
    <div className={styles.tag_wrapper}>
      <div className={cx(styles.tag,styles[type])}>
        {type.toUpperCase()}
      </div>
    </div>
  )
}
export default Tag;
