import cx from 'classnames';
import React, {CSSProperties, forwardRef, ReactElement, ReactNode, useState} from 'react';
import {INavigationHeaderNavItem} from "@digit-fe/digit-component/dist/NavigationHeader/index";
import {MediaContextProvider} from '@/constants/media_query';
import MobileHeader from '@/components/NavigationHeader/MobileHeader';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import PcHeader from '@/components/NavigationHeader/PcHeader';
import '@/components/NavigationHeader/styles/index.less';
import {isMobile} from '@/utils/index';
import PcComponentWrapper from "@/components/PcComponentWrapper/index";
import PhoneComponentWrapper from "@/components/PhoneComponentWrapper/index";
import {INavigationHeaderMenuItem} from "@/types/component";

interface StandardProps {
  // 组件最外层的className
  className?: string;

  // 组件的children
  children?: ReactElement | ReactNode;

  // 组件最外层的style
  style?: CSSProperties;
}

export interface INavigationHeaderProps extends StandardProps {
  uid?: string | number;
  username?: string;
  env?: 'test' | 'prod';
  siteName?: string;
  testPassportUrl?: string;
  prodPassportUrl?: string;
  onSearch?: (text: string) => void;
  onLogout?: () => void;
  onLogin?: () => void;
  rightMenu?: ('user' | 'bell' | 'account' | ReactElement)[];
  dropDownMenu?: ('home' | 'task' | 'class' | ReactElement)[];
  menu?: INavigationHeaderMenuItem[];
  navItem?: INavigationHeaderNavItem[];
  onLogoClick?: () => void;
  searchComponent?: false | ReactElement;
  onMenuClick?: (data: INavigationHeaderMenuItem) => void;
  onMonitorChange?: (data: { uid: string; username: string }) => void;
  onSiteNameClick?: () => void;
}

const NavigationHeader = forwardRef<any, INavigationHeaderProps>(
  (props, ref) => {
    const {
      navItem,
      searchComponent,
      onSiteNameClick,
      onMonitorChange,
      siteName,
      className,
      uid,
      username,
      env = 'test',
      onLogout,
      onLogin,
      menu = [],
      rightMenu,
      onSearch,
      onMenuClick,
      onLogoClick,
      dropDownMenu,
      testPassportUrl = 'https://passport.fanruan.com',
      prodPassportUrl = 'https://fanruanclub.com',
    } = props;
    const [activeMenu, setActiveMenu] = useState<INavigationHeaderMenuItem>();

    return (
      <NavigationHeaderContext.Provider
        value={{
          navItem,
          searchComponent,
          onSiteNameClick,
          rightMenu,
          onSearch,
          siteName,
          dropDownMenu,
          onLogoClick,
          activeMenuTitleOnClick: activeMenu?.onClick,
          activeMenuTitle: activeMenu?.title,
          activeMenuId: activeMenu?.id,
          device: isMobile() ? 'mobile' : 'pc',
          onLogout,
          onLogin,
          username,
          uid,
          testPassportUrl,
          prodPassportUrl,
          env,
          menu,
          onMenuClick,
        }}
      >
        <header className={cx('dr-nav-wrapper', className)}>
          <MediaContextProvider>
            <PcComponentWrapper style={{width: '100%'}}>
              <PcHeader/>
            </PcComponentWrapper>
            <PhoneComponentWrapper className="dr-nav-mobile-wrapper">
              <MobileHeader ref={ref}/>
            </PhoneComponentWrapper>
          </MediaContextProvider>
        </header>
      </NavigationHeaderContext.Provider>
    );
  },
);

export default NavigationHeader;
