import {Space} from 'antd';
import React, {useContext} from 'react';
import HeaderAccountBtn from '@/components/NavigationHeader/HeaderAccountBtn';
import HeaderUnReadBell from '@/components/NavigationHeader/HeaderUnReadBell';
import HeaderUser from '@/components/NavigationHeader/HeaderUser';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import {login} from '@/utils/index';

const HeaderLogin: React.FC = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {rightMenu, uid, env, onLogin} = NavigationHeaderContextProps;
  const defaultMenu = {
    user: <HeaderUser key="user"/>,
    bell: <HeaderUnReadBell key="bell"/>,
    account: <HeaderAccountBtn key="account"/>,
  };
  const renderRightMenu = () => {
    if (Array.isArray(rightMenu)) {
      return rightMenu.map((item) => {
        if (typeof item === 'string') {
          if (['user', 'bell', 'account'].includes(item)) {
            return defaultMenu[item];
          }
          return null;
        }
        if (React.isValidElement(item)) {
          return <>{item}</>;
        }
        return null;
      });
    }
    return (['user', 'bell', 'account'] as ('user' | 'bell' | 'account')[]).map(
      (item) => {
        return <div key={`default_${item}`}>{defaultMenu[item]}</div>;
      },
    );
  };
  return (
    <div>
      {uid ? (
        <Space size={24}>{renderRightMenu()}</Space>
      ) : (
        <div
          className="dr-nav-login-btn"
          onClick={() => {
            if (onLogin) {
              onLogin();
            } else {
              login({env});
            }
          }}
        >
          登录
        </div>
      )}
    </div>
  );
};
export default HeaderLogin;
