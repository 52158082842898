import {SearchOutlined} from '@ant-design/icons';
import {Input} from 'antd';
import React, {useContext, useRef} from 'react';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';

const HeaderInput: React.FC = () => {
  const headerInputRef = useRef<any>(null);
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {searchComponent, onSearch, uid} = NavigationHeaderContextProps;
  const defaultSearchComponent = (
    <Input
      ref={headerInputRef}
      allowClear
      placeholder={uid ? '请输入搜索内容' : '登录后方可搜索'}
      className="dr-nav-input"
      suffix={
        <SearchOutlined
          style={{color: '#8596AC'}}
          onClick={() => {
            const {value} = headerInputRef.current.input;
            if (value) {
              if (onSearch) {
                onSearch?.(value);
              } else {
                window.open(`https://search.fanruan.com/?q=${value}`);
              }
            }
          }}
        />
      }
      disabled={!uid}
      onPressEnter={(e) => {
        const {value} = e.target as HTMLInputElement;
        if (value) {
          if (onSearch) {
            onSearch?.(value);
          } else {
            window.open(`https://search.fanruan.com/?q=${value}`);
          }
        }
      }}
    />
  );
  const renderInputCompoent = () => {
    if (searchComponent === undefined) {
      return defaultSearchComponent;
    } if (searchComponent === false) {
      return null;
    }
    return searchComponent;
  };
  return <>{renderInputCompoent()}</>;
};
export default HeaderInput;
