// 获取用户资料
import { requestGet } from '@/services/request';
import {requestCasGet} from "@/services/casRequest";

// 用户登录
export async function login(code: string) {
  return requestGet('/v1/user/login', { code });
}
// 用户刷新token
export async function digitRefreshToken(params: {
  username: string;
  uid: string;
  refreshToken: string;
}) {
  return requestGet('/v1/user/token/refresh', params);
}
// 用户退出登录
export async function digitLogout(params: {
  uid: number;
  accessToken: string;
}): Promise<{
  code: number;
  success: boolean;
}> {
  return requestGet('/v1/user/logout', params);
}
// 从sid获取东西
export async function apiGetSid(sid: string): Promise<{
  code: number;
  success: boolean;
  data: null;
  msg: '操作成功';
  status: null;
}> {
  return requestGet('/api/idapi/sid', {sid});
}

// 获取用户基本信息
export async function apiGetUserInfo(): Promise<{
  code: number;
  success: boolean;
  data: null | {
    isTeacher: boolean;
    isStudent: boolean;
    isRealname: boolean;
    isAdmin: boolean;
    realname: string;
    username: string;
    IDNum: string;
    mobile: string;
    uid: string;
    isSupplierState: boolean
  };
  msg: '操作成功';
  status: null;
}> {
  return requestCasGet('/v1/cert/basic/info', {});
}
