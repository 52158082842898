import axios, {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import {message} from 'antd';
import {casLogin, removeAllCookie, setCookie} from '@/utils';

// const baseURL = process.env.NEXT_PUBLIC_SERVICE_URL;
const baseURL = '';
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json;charse=UTF-8',
  },
});
const pendingMap = new Map();

/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
function getPendingKey(config: AxiosRequestConfig<any>) {
  const { url, method, params } = config;
  let { data } = config;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
function addPending(config: AxiosRequestConfig<any>) {
  const newConfig = config;
  const pendingKey = getPendingKey(newConfig);
  newConfig.cancelToken =
    newConfig.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config: AxiosRequestConfig<any>) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}

export function getRefreshDigitTokenApi(
  refreshToken: string,
  uid: string,
  username: string,
) {
  return axios({
    url: `${baseURL}/v1/user/token/refresh?uid=${uid}&username=${username}&refreshToken=${refreshToken}`,
    method: 'get',
  });
}
function refreshPassportTokenApi(
  appid: number,
  uid: number,
  refresh_token: number,
) {
  return axios({
    url: `${process.env.NEXT_PUBLIC_PASSPORT_URL}/v1/token/refresh/?appid=${appid}&uid=${uid}&refresh_token=${refresh_token}`,
    method: 'get',
  });
}

async function refreshTokenApi(refreshToken: string, uid: string) {
  try {
    const data = await getRefreshDigitTokenApi(refreshToken, uid, 'zhaojunzhe');
    if (data.data.code === 200) {
      const { access_token, expires_time, refresh_token } = data.data.data;
      setCookie('digit_expires_time', expires_time);
      setCookie('digit_refresh_token', refresh_token);
      setCookie('digit_access_token', access_token);
    }
  } catch (e) {
    removeAllCookie();
  }
}
export const requestFormDataPost = async (
  api: string,
  params: { [key: string]: any },
): Promise<any> => {
  const paramFormData = new FormData();
  for (const key of Object.keys(params)) {
    if (params[key] !== null) {
      paramFormData.append(key, params[key]);
    }
  }
  return new Promise((resolve, reject) => {
    instance
      .post(api, paramFormData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const requestPost = async (
  api: string,
  params: object,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    instance
      .post(api, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const requestGet = async (
  api: string,
  params?: object,
  extraHeader?: AxiosRequestHeaders,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    instance
      .get(api, {
        params,
        headers: extraHeader,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

instance.interceptors.request.use(
  async (config) => {
    removePending(config);
    addPending(config);
    const newConfig = config;
    newConfig.withCredentials = true;
    // const appid = getCookie('fr_appid');
    // const uid = getCookie('fr_uid');
    // const expiresTime = getCookie('digit_expires_time');
    // const accessToken = getCookie('digit_access_token');
    // const refreshToken = getCookie('digit_refresh_token');
    // const passportAccessToken = getCookie('fr_access_token');
    // const passportRefreshToken = getCookie('fr_refresh_token');
    // const passportExpiresTime = getCookie('fr_expires_time');
    // if (uid && accessToken && appid) {
    //   // 通行证token
    //   let passportToken = Buffer.from(`${appid}.${passportAccessToken}.${uid}`,'utf-8').toString('base64');
    //   let token = Buffer.from(`${appid}.${accessToken}.${uid}`,'utf-8').toString('base64');
    //   console.log('digit_token是');
    //   console.log(token);
    //   if (moment().isAfter(moment(expiresTime * 1000 - 5*60*1000))) {
    //     try {
    //       const data = await getRefreshDigitTokenApi(refreshToken,uid,'zhaojunzhe');
    //       if (data.data.code === 200){
    //         const {access_token,expires_time,refresh_token} = data.data.data;
    //         setCookie('digit_expires_time',expires_time);
    //         setCookie('digit_refresh_token',refresh_token);
    //         setCookie('digit_access_token',access_token);
    //         token = Buffer.from(`${appid}.${accessToken}.${uid}`,'utf-8').toString('base64');
    //       }
    //     } catch (e) {
    //       removeAllCookie();
    //     }
    //   }
    //   if (moment().isAfter(moment(passportExpiresTime * 1000 - 5*60*1000))) {
    //     try {
    //       const data = await refreshPassportTokenApi(appid,uid,passportRefreshToken);
    //       console.log('社区新请求的token');
    //       console.log(data);
    //       if (data.data.code === 200){
    //         const {access_token,expires_time,refresh_token} = data.data.data;
    //         setCookie('fr_access_token',access_token);
    //         setCookie('fr_refresh_token',refresh_token);
    //         setCookie('fr_expires_time',expires_time);
    //         passportToken = Buffer.from(`${appid}.${accessToken}.${uid}`,'utf-8').toString('base64');
    //       }
    //     } catch (e) {
    //       passportToken = '';
    //       removeAllCookie();
    //     }
    //   }
    //   (newConfig.headers as any).authentication = token;
    //   (newConfig.headers as any).authenticationID = passportToken;
    // }
    return newConfig;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  async (response) => {
    // 对响应数据做点什么
    removePending(response.config);
    if (response.data.code === 500 || response.data.code === 502) {
      message.warning('请求异常，请稍后重试');
    }
    if (response.data.code === 401 && typeof window !== undefined) {
      message.warning('当前无权限查看此页面或未登录');
      casLogin();
      // const requestMethod = response.config.method;
      // await requestGet((response.config.url as string),response.config.params);
      // message.warning('当前无权限查看此页面或未登录');
      // window.location.href = `${calcUrl().passport_url}/login/signin/?referrer=${decodeURIComponent(window.location.href)}`
    }
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么
    console.log('请求出错');
    console.log(error);

    if (error.response.status === 500 || error.response.status === 502) {
      message.warning('请求异常，请稍后重试');
    }
    if (error.response.status === 401 && typeof window !== undefined) {
      casLogin();
    }

    const newError = error;
    if (newError.config) {
      removePending(newError.config);
    }
    return Promise.reject(newError);
  },
);
