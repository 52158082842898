import { Buffer } from 'buffer';
import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { message } from 'antd';
import {casLogin, getCookie, removeAllCookie, setCookie} from '@/utils';

const shequApiURL = process.env.NEXT_PUBLIC_SHEQU_SERVICE_URL;

const shequRequestInstance = axios.create({
  baseURL: shequApiURL,
  headers: {
    'Content-Type': 'application/json;charse=UTF-8',
  },
});

const pendingMap = new Map();

/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
function getPendingKey(config: AxiosRequestConfig<any>) {
  const { url, method, params } = config;
  let { data } = config;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
function addPending(config: AxiosRequestConfig<any>) {
  const newConfig = config;
  const pendingKey = getPendingKey(newConfig);
  newConfig.cancelToken =
    newConfig.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config: AxiosRequestConfig<any>) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}
export function refreshShequToken(
  appid: number,
  uid: number,
  refresh_token: number,
) {
  return axios({
    url: `${process.env.NEXT_PUBLIC_PASSPORT_URL}/v1/token/refresh/?appid=${appid}&uid=${uid}&refresh_token=${refresh_token}`,
    method: 'get',
  });
}
shequRequestInstance.interceptors.request.use(
  async (config) => {
    // removePending(config); //在一个axios发送前执行一下取消操作
    // 在发送请求之前做些什么
    removePending(config);
    addPending(config);
    const newConfig = config;
    const appid = getCookie('fr_appid');
    const uid = getCookie('fr_uid');
    const expiresTime = getCookie('fr_expires_time');
    const accessToken = getCookie('fr_access_token');
    const refreshToken = getCookie('fr_refresh_token');
    if (uid && accessToken && appid) {
      let token = Buffer.from(
        `${appid}.${accessToken}.${uid}`,
        'utf-8',
      ).toString('base64');
      console.log('shequ_token是');
      console.log(token);
      if (moment().isAfter(moment(expiresTime * 1000 - 5 * 60 * 1000))) {
        try {
          const data = await refreshShequToken(appid, uid, refreshToken);
          console.log('社区新请求的token');
          console.log(data);
          if (data.data.code === 200) {
            const { access_token, expires_time, refresh_token } =
              data.data.data;
            setCookie('fr_access_token', access_token);
            setCookie('fr_refresh_token', refresh_token);
            setCookie('fr_expires_time', expires_time);
            token = Buffer.from(
              `${appid}.${accessToken}.${uid}`,
              'utf-8',
            ).toString('base64');
          }
        } catch (e) {
          token = '';
          removeAllCookie();
        }
      }
      (newConfig.headers as any).authentication = token;
    }
    return newConfig;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);
shequRequestInstance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    removePending(response.config);
    if (response.data.code === 500 || response.data.code === 502) {
      message.warning('请求异常，请稍后重试');
    }
    if (response.data.code === 401 && typeof window !== undefined) {
      message.warning('无权限，请去登录');
      casLogin();
      // window.location.href = `${calcUrl().passport_url}/login/signin/?referrer=${decodeURIComponent(window.location.href)}`
    }
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么
    const newError = error;
    if (newError.config) {
      removePending(newError.config);
    }
    return Promise.reject(newError);
  },
);
export const shequRequestPost = async (api: string, params: object) => {
  return new Promise((resolve, reject) => {
    shequRequestInstance
      .post(api, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const shequRequestGet = async (
  api: string,
  params: object,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    shequRequestInstance
      .get(api, {
        params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
