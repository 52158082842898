import {MenuOutlined} from '@ant-design/icons';
import {Space} from 'antd';
import cx from 'classnames';
import React, {forwardRef, useContext} from 'react';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import HeaderLogin from '@/components/NavigationHeader/HeaderLogin';
import HeaderDrawer from '@/components/NavigationHeader/HeaderDrawer';

const MobileHeader = forwardRef<any, any>((props, ref) => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {menu, onMenuClick, activeMenuId, activeMenuTitle} =
    NavigationHeaderContextProps;
  const renderMenu = () => {
    if (menu && Array.isArray(menu)) {
      return menu.map((item) => {
        return (
          <div
            className={cx(
              'dr-nav-drawer-item',
              item.id === activeMenuId && 'dr-nav-drawer-item-active',
            )}
            key={item.id}
            onClick={() => {
              if (onMenuClick) {
                onMenuClick(item);
              } else {
                window.open(item.path);
              }
            }}
          >
            {item.title}
          </div>
        );
      });
    }
    return null;
  };
  return (
    <div className="dr-nav">
      <Space>
        <HeaderDrawer ref={ref} button={<MenuOutlined/>}>
          {renderMenu()}
        </HeaderDrawer>
        <div className="dr-nav-title">{activeMenuTitle}</div>
      </Space>

      <Space size={24}>
        <HeaderLogin/>
      </Space>
    </div>
  );
});
export default MobileHeader;
