import React from 'react';
import cx from 'classnames';
import Tag from '@/components/ui/tag';
import Navigate from '@/components/ui/navigate';
import styles from './styles.module.less';

const ClassIntroPc: React.FC = () => {
  return (
    <div className={styles.class_intro_wrapper_pc_wrapper}>
      <div className={styles.class_intro_wrapper_pc}>
        <div className={styles.head}>
          <div className={styles.title}>认证体系</div>
          <p className={styles.intro_subtitle}>
            面向学生、数据分析师、报表工程师等人群，专业技术领域内的技能认证
          </p>
        </div>
        <div className={styles.class_intro_wrapper_pc_table}>
          <table
            className={styles.class_intro_wrapper_pc_table}
            cellSpacing={0}
          >
            <thead>
              <tr className={styles.table_head_tr}>
                <td className={styles.table_head}>
                  <div
                    className={cx(
                      styles.table_head_body,
                      styles.table_head_body_empty,
                    )}
                  />
                </td>
                <td className={styles.table_head} height={56}>
                  <div className={styles.table_head_body}>
                    <div className={styles.row}>
                      <p className={styles.title}>入门认证</p>
                      <Tag type="fca" />
                    </div>
                    <p className={styles.subtitle}>
                      具备基本的理论知识和工具使用能力
                    </p>
                  </div>
                </td>
                <td className={styles.table_head} height={80}>
                  <div className={styles.table_head_body}>
                    <div className={styles.row}>
                      <p className={styles.title}>资深认证</p>
                      <Tag type="fcp" />
                    </div>
                    <p className={styles.subtitle}>
                      掌握企业实际岗位中需要的知识和能力
                    </p>
                    <p className={styles.subtitle}>能够完全胜任相关岗位</p>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.table_row}>
                <td className={styles.cell}>
                  <p className={styles.cell_title}>IT开发者</p>
                  <p className={styles.cell_subtitle}>IT Developer</p>
                </td>
                <td className={styles.cell}>
                  <div className={styles.cell_body} style={{ height: 200 }}>
                    <Navigate
                      className={styles.navigate}
                      text="FCA-FineReport"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCRA_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCA-数知鸟"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCA_SHUZHINIAO_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCA-FineDataLink"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FINEDATALINK_CERTID}`}
                    />
                  </div>
                </td>
                <td className={styles.cell}>
                  <div className={styles.cell_body}>
                    <Navigate
                      className={styles.navigate}
                      text="FCP-报表开发工程师"
                      type="fcp"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCRP_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCP-报表交付工程师"
                      type="fcp"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCRPD_CERTID}`}
                    />
                    {/*<Navigate*/}
                    {/*  className={styles.navigate}*/}
                    {/*  text="FCP-FineReport插件开发工程师"*/}
                    {/*  type="fcp"*/}
                    {/*  url={`/detail/${process.env.NEXT_PUBLIC_FCP_PLUGIN_CERTID}`}*/}
                    {/*/>*/}
                  </div>
                </td>
              </tr>
              <tr className={styles.table_row}>
                <td className={styles.cell}>
                  <p className={styles.cell_title}>业务人员</p>
                  <p className={styles.cell_subtitle}>Professionals</p>
                </td>
                <td className={styles.cell}>
                  <div className={styles.cell_body}>
                    <Navigate
                      className={styles.navigate}
                      text="FCA-FineBI"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCBA_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCA-简道云"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCJA_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCA-业务分析理论"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCAA_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCA-数据分析理论"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCA_DATA_ANALYSIS_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCA-九数云"
                      type="fca"
                      url={`/detail/${process.env.NEXT_PUBLIC_JIUSHUYUN_CERTID}`}
                    />
                  </div>
                </td>
                <td className={styles.cell}>
                  <div className={styles.cell_body}>
                    <Navigate
                      className={styles.navigate}
                      text="FCP-FineBI"
                      type="fcp"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCBP6_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCP-零代码开发工程师"
                      type="fcp"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCJP_CERTID}`}
                    />
                    <Navigate
                      className={styles.navigate}
                      text="FCP-商业数据分析师"
                      type="fcp"
                      url={`/detail/${process.env.NEXT_PUBLIC_FCP_BUSINESS_DATA_ANALYSIS_CERTID}`}
                    />
                    {/*<Navigate*/}
                    {/*  className={styles.navigate}*/}
                    {/*  text="FCP-业务分析师"*/}
                    {/*  type="fcp"*/}
                    {/*  url={`/detail/${process.env.NEXT_PUBLIC_FCAP_CERTID}`}*/}
                    {/*/>*/}
                    {/*<Navigate*/}
                    {/*  className={styles.navigate}*/}
                    {/*  text="FCP-数据分析师"*/}
                    {/*  type="fcp"*/}
                    {/*  url={`/detail/${process.env.NEXT_PUBLIC_FCP_DATA_ANALYSIS_CERTID}`}*/}
                    {/*/>*/}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ClassIntroPc;
