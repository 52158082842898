import axios, {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import {message} from 'antd';
import {casLogin, removeAllCookie, setCookie} from '@/utils';

// const baseURL = process.env.NEXT_PUBLIC_SERVICE_URL;
const baseURL = '';
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json;charse=UTF-8',
  },
});
const pendingMap = new Map();

/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
function getPendingKey(config: AxiosRequestConfig<any>) {
  const { url, method, params } = config;
  let { data } = config;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
function addPending(config: AxiosRequestConfig<any>) {
  const newConfig = config;
  const pendingKey = getPendingKey(newConfig);
  newConfig.cancelToken =
    newConfig.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config: AxiosRequestConfig<any>) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}
export const requestCasGet = async (
  api: string,
  params?: object,
  extraHeader?: AxiosRequestHeaders,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    instance
      .get(api, {
        params,
        headers: extraHeader,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

instance.interceptors.request.use(
  async (config) => {
    removePending(config);
    addPending(config);
    const newConfig = config;
    newConfig.withCredentials = true;
    return newConfig;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);
instance.interceptors.response.use(
  async (response) => {
    // 对响应数据做点什么
    removePending(response.config);
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么
    console.log('请求出错');
    console.log(error.response);
    if (error.response.status === 500 || error.response.status === 502) {
      message.warning('请求异常，请稍后重试');
    }
    if (error.response.status === 401 && typeof window !== undefined) {
      // message.warning('当前无权限查看此页面或未登录');
      // casLogin();
      // const requestMethod = response.config.method;
      // await requestGet((response.config.url as string),response.config.params);
      // message.warning('当前无权限查看此页面或未登录');
      // window.location.href = `${calcUrl().passport_url}/login/signin/?referrer=${decodeURIComponent(window.location.href)}`
    }
    return Promise.reject(error);
  },
);
