import {IdcardOutlined} from '@ant-design/icons';
import React, {useContext} from 'react';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';

const HeaderAccountBtn: React.FC = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {testPassportUrl, prodPassportUrl, env} =
    NavigationHeaderContextProps;

  return (
    <div
      className="dr-nav-icon-item"
      onClick={() => {
        window.open(env === 'test' ? testPassportUrl : prodPassportUrl);
      }}
    >
      <div className="icon-wrapper">
        <IdcardOutlined className="icon"/>
      </div>
      <div>账户</div>
    </div>
  );
};
export default HeaderAccountBtn;
