import Cookies from 'universal-cookie';
import getConfig from 'next/config';
import { VerifyEnum } from '@/types/verify';
import { TExamKind } from '@/types';

const { publicRuntimeConfig } = getConfig();

export const isServer = () => typeof window === 'undefined';
export const checkArrayEqual = (arrayA: any[], arrayB: any[]) => {
  if (Array.isArray(arrayA) && Array.isArray(arrayB)) {
    return arrayA.join(',') === arrayB.join(',');
  }
  return false;
};
export const px2vw = (px: number) => {
  // 1920为设计稿的宽
  return `${(px / 1920) * 100}vw`;
};
export const isMobile = () => {
  if (typeof window === 'undefined') return false;
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  function mq(query: string) {
    return typeof window !== 'undefined' && window.matchMedia(query).matches;
  }

  // @ts-ignore
  if ('ontouchstart' in window) return true;
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    '',
  ); // include the 'heartz' - https://git.io/vznFH
  return mq(query);
};

// 得到用户头像
export function getAvatar(
  uid: string | number,
  size: 'big' | 'small' = 'small',
): string {
  return `https://bbs.fanruan.com/uc_server/avatar.php?uid=${uid}&size=${size}`;
}

export function setCookie(key: string, value: any) {
  const cookies = new Cookies();
  const cookieData = cookies.get('key');
  if (cookieData) {
    cookies.remove('key');
    cookies.set(key, value, {
      path: '/',
    });
  }
  return cookies.set(key, value, {
    path: '/',
  });
}

export function removeAllCookie() {
  const cookies = new Cookies();
  const allCookie = cookies.getAll();
  for (const key of Object.keys(allCookie)) {
    cookies.remove(key);
  }
}

export function removeCookie(key: string) {
  const cookies = new Cookies();
  cookies.remove(key);
}

export function getCookie(key: string, extra?: any) {
  let cookies = new Cookies();
  if (extra) {
    cookies = new Cookies(extra);
  }
  return cookies.get(key);
}

export function calcCertIdByType(type: VerifyEnum | TExamKind) {
  if (type) {
    if (typeof type === 'number') {
      switch (type) {
        case VerifyEnum.FCAP:
          return process.env.NEXT_PUBLIC_FCAP_CERTID;
        case VerifyEnum.FCAA:
          return process.env.NEXT_PUBLIC_FCAA_CERTID;
        case VerifyEnum.FCBA:
          return process.env.NEXT_PUBLIC_FCBA_CERTID;
        case VerifyEnum.FCBA5:
          return process.env.NEXT_PUBLIC_FCBA5_CERTID;
        case VerifyEnum.FCBP:
          return process.env.NEXT_PUBLIC_FCBP_CERTID;
        case VerifyEnum.FCBP6:
          return process.env.NEXT_PUBLIC_FCBP6_CERTID;
        case VerifyEnum.FCJA:
          return process.env.NEXT_PUBLIC_FCJA_CERTID;
        case VerifyEnum.FCJP:
          return process.env.NEXT_PUBLIC_FCJP_CERTID;
        case VerifyEnum.FCRA:
          return process.env.NEXT_PUBLIC_FCRA_CERTID;
        case VerifyEnum.FCRP:
          return process.env.NEXT_PUBLIC_FCRP_CERTID;
        case VerifyEnum.FCRPD:
          return process.env.NEXT_PUBLIC_FCRPD_CERTID;
        case VerifyEnum.FCA_SHUZHINIAO:
          return process.env.NEXT_PUBLIC_FCA_SHUZHINIAO_CERTID;
        case VerifyEnum.FCP_PLUGIN:
          return process.env.NEXT_PUBLIC_FCP_PLUGIN_CERTID;
        case VerifyEnum.FCA_DATA_ANALYSIS:
          return process.env.NEXT_PUBLIC_FCA_DATA_ANALYSIS_CERTID;
        case VerifyEnum.FCA_JIUSHUYUN:
          return process.env.NEXT_PUBLIC_JIUSHUYUN_CERTID;
        case VerifyEnum.FCP_DATA_ANALYSIS:
          return process.env.NEXT_PUBLIC_FCP_DATA_ANALYSIS_CERTID;
        case VerifyEnum.FINEDATALINK:
          return process.env.NEXT_PUBLIC_FINEDATALINK_CERTID;
        default:
          return process.env.NEXT_PUBLIC_FCRA_CERTID;
      }
    } else {
      const typeMapping: { type: TExamKind; certId: string }[] = [
        {
          type: 'fcra',
          certId: process.env.NEXT_PUBLIC_FCRA_CERTID as string,
        },
        {
          type: 'fcrp',
          certId: process.env.NEXT_PUBLIC_FCRP_CERTID as string,
        },
        {
          type: 'fcrpd',
          certId: process.env.NEXT_PUBLIC_FCRPD_CERTID as string,
        },
        {
          type: 'fcba',
          certId: process.env.NEXT_PUBLIC_FCBA_CERTID as string,
        },
        {
          type: 'fcba5',
          certId: process.env.NEXT_PUBLIC_FCBA5_CERTID as string,
        },
        {
          type: 'fcbp',
          certId: process.env.NEXT_PUBLIC_FCBP_CERTID as string,
        },
        {
          type: 'fcbp6',
          certId: process.env.NEXT_PUBLIC_FCBP6_CERTID as string,
        },
        {
          type: 'fcaa',
          certId: process.env.NEXT_PUBLIC_FCAA_CERTID as string,
        },
        {
          type: 'fcap',
          certId: process.env.NEXT_PUBLIC_FCAP_CERTID as string,
        },
        {
          type: 'fcja',
          certId: process.env.NEXT_PUBLIC_FCJA_CERTID as string,
        },
        {
          type: 'fcjp',
          certId: process.env.NEXT_PUBLIC_FCJP_CERTID as string,
        },
        {
          type: 'fca_shuzhiniao',
          certId: process.env.NEXT_PUBLIC_FCA_SHUZHINIAO_CERTID as string,
        },
        {
          type: 'fcp_plugin',
          certId: process.env.NEXT_PUBLIC_FCP_PLUGIN_CERTID as string,
        },
        {
          type: 'fca_data_analysis',
          certId: process.env.NEXT_PUBLIC_FCA_DATA_ANALYSIS_CERTID as string,
        },
        {
          type: 'fca_jiushuyun',
          certId: process.env.NEXT_PUBLIC_JIUSHUYUN_CERTID as string,
        },
        {
          type: 'fcp_data_analysis',
          certId: process.env.NEXT_PUBLIC_FCP_DATA_ANALYSIS_CERTID as string,
        },
        {
          type: 'finedatalink',
          certId: process.env.NEXT_PUBLIC_FINEDATALINK_CERTID as string,
        },
        {
          type: 'fcp_business_data_analysis',
          certId: process.env
            .NEXT_PUBLIC_FCP_BUSINESS_DATA_ANALYSIS_CERTID as string,
        },
      ];
      const findItem = typeMapping.find((typeObj) => typeObj.type === type);
      if (findItem) {
        return findItem.certId;
      }
      return process.env.NEXT_PUBLIC_FCRA_CERTID;
    }
  }
  return process.env.NEXT_PUBLIC_FCRA_CERTID;
}

export function calcCertType(type: VerifyEnum) {
  switch (type) {
    case VerifyEnum.FCAP:
      return 'FCAP';
    case VerifyEnum.FCAA:
      return 'FCAA';
    case VerifyEnum.FCBA:
      return 'FCBA';
    case VerifyEnum.FCBA5:
      return 'FCBA5';
    case VerifyEnum.FCBP:
      return 'FCBP';
    case VerifyEnum.FCBP6:
      return 'FCBP6';
    case VerifyEnum.FCJA:
      return 'FCJA';
    case VerifyEnum.FCJP:
      return 'FCJP';
    case VerifyEnum.FCRA:
      return 'FCRA';
    case VerifyEnum.FCRP:
      return 'FCRP';
    case VerifyEnum.FCRPD:
      return 'FCRPD';
    case VerifyEnum.FCA_SHUZHINIAO:
      return 'FCA_SHUZHINIAO';
    case VerifyEnum.FCA_DATA_ANALYSIS:
      return 'FCA_DATA_ANALYSIS';
    case VerifyEnum.FCP_PLUGIN:
      return 'FCP_PLUGIN';
    case VerifyEnum.FCA_JIUSHUYUN:
      return 'FCA_JIUSHUYUN';
    case VerifyEnum.FCP_DATA_ANALYSIS:
      return 'FCP_DATA_ANALYSIS';
    default:
      return '';
  }
}

export function createCertIdArray() {
  const fcra_certId = process.env.NEXT_PUBLIC_FCRA_CERTID;
  const fcrpd_certId = process.env.NEXT_PUBLIC_FCRPD_CERTID;
  const fcrp_certId = process.env.NEXT_PUBLIC_FCRP_CERTID;
  const fcba_certId = process.env.NEXT_PUBLIC_FCBA_CERTID;
  const fcba5_certId = process.env.NEXT_PUBLIC_FCBA5_CERTID;
  const fcbp_certId = process.env.NEXT_PUBLIC_FCBP_CERTID;
  const fcbp6_certId = process.env.NEXT_PUBLIC_FCBP6_CERTID;
  const fcaa_certId = process.env.NEXT_PUBLIC_FCAA_CERTID;
  const fcap_certId = process.env.NEXT_PUBLIC_FCAP_CERTID;
  const fcjp_certId = process.env.NEXT_PUBLIC_FCJP_CERTID;
  const fcja_certId = process.env.NEXT_PUBLIC_FCJA_CERTID;
  const fca_shuzhiniao_certId = process.env.NEXT_PUBLIC_FCA_SHUZHINIAO_CERTID;
  const fca_data_analysis_certId =
    process.env.NEXT_PUBLIC_FCA_DATA_ANALYSIS_CERTID;
  const fcp_data_analysis_certId =
    process.env.NEXT_PUBLIC_FCP_DATA_ANALYSIS_CERTID;
  const fcp_plugin_certId = process.env.NEXT_PUBLIC_FCP_PLUGIN_CERTID;
  const fca_jiushuyun_certId = process.env.NEXT_PUBLIC_JIUSHUYUN_CERTID;
  const finedatalink_certId = process.env.NEXT_PUBLIC_FINEDATALINK_CERTID;
  const fcp_business_data_analysis_certID =
    process.env.NEXT_PUBLIC_FCP_BUSINESS_DATA_ANALYSIS_CERTID;
  return [
    {
      type: 'fca_shuzhiniao',
      name: 'FCA-数知鸟',
      certEnum: VerifyEnum.FCA_SHUZHINIAO,
      certId: fca_shuzhiniao_certId,
    },
    {
      type: 'fcra',
      name: 'FCA-FineReport',
      certEnum: VerifyEnum.FCRA,
      certId: fcra_certId,
    },
    {
      type: 'fcrpd',
      certEnum: VerifyEnum.FCRPD,
      name: 'FCP-报表交付工程师',
      certId: fcrpd_certId,
    },
    {
      type: 'fcrp',
      name: 'FCP-报表开发工程师',
      certEnum: VerifyEnum.FCRP,
      certId: fcrp_certId,
    },
    {
      type: 'fcbp',
      name: 'FCP-FineBI',
      certEnum: VerifyEnum.FCBP,
      certId: fcbp_certId,
    },
    {
      type: 'fcbp6',
      name: 'FCP-FineBI V6.0',
      certEnum: VerifyEnum.FCBP6,
      certId: fcbp6_certId,
    },
    {
      type: 'fcba',
      certEnum: VerifyEnum.FCBA,
      name: 'FCA-FineBI',
      certId: fcba_certId,
    },
    {
      type: 'fcba5',
      certEnum: VerifyEnum.FCBA5,
      name: 'FCA-FineBI V5.x',
      certId: fcba5_certId,
    },
    {
      type: 'fcaa',
      certEnum: VerifyEnum.FCAA,
      name: 'FCA-业务分析理论',
      certId: fcaa_certId,
    },
    {
      type: 'fcap',
      certEnum: VerifyEnum.FCAP,
      name: 'FCP-业务分析师',
      certId: fcap_certId,
    },
    {
      type: 'fcjp',
      certEnum: VerifyEnum.FCJP,
      name: 'FCP-零代码开发工程师',
      certId: fcjp_certId,
    },
    {
      type: 'fcja',
      name: 'FCA-简道云',
      certEnum: VerifyEnum.FCJA,
      certId: fcja_certId,
    },
    {
      type: 'fca_data_analysis',
      name: 'FCA-数据分析理论',
      certEnum: VerifyEnum.FCA_DATA_ANALYSIS,
      certId: fca_data_analysis_certId,
    },
    {
      type: 'fcp_data_analysis',
      name: 'FCP-数据分析师',
      certEnum: VerifyEnum.FCP_DATA_ANALYSIS,
      certId: fcp_data_analysis_certId,
    },
    {
      type: 'fcp_plugin',
      name: 'FCP-FineReport插件开发工程师',
      certEnum: VerifyEnum.FCP_PLUGIN,
      certId: fcp_plugin_certId,
    },
    {
      type: 'fcp_jiushuyun',
      name: 'FCP-九数云',
      certEnum: VerifyEnum.FCA_JIUSHUYUN,
      certId: fca_jiushuyun_certId,
    },
    {
      type: 'fca_finedatalink',
      name: 'FCA-FineDataLink',
      certEnum: VerifyEnum.FINEDATALINK,
      certId: finedatalink_certId,
    },
    {
      type: 'fcp_business_data_analysis',
      name: 'FCP-商业数据分析师',
      certEnum: VerifyEnum.FCP_BUSINESS_DATA_ANALYSIS,
      certId: fcp_business_data_analysis_certID,
    },
  ];
}

export function calcExamTitle(type: VerifyEnum | TExamKind) {
  const certIdArray = createCertIdArray();
  let findItem;
  if (typeof type === 'number') {
    findItem = certIdArray.find((item) => item.certEnum === type);
  } else {
    findItem = certIdArray.find((item) => item.type === type);
  }
  if (findItem) {
    return findItem.name;
  }
  return '';
}

export function getEnvParams(key: string) {
  return publicRuntimeConfig[key];
}

export function findCertInfoByCertId(
  id: string,
  field: 'type' | 'name' = 'type',
) {
  const certArray = createCertIdArray();
  const findItem = certArray.find((item) => item.certId === id);
  if (findItem) {
    return findItem[field];
  }
  return '';
}

export function calcTime(time: number) {
  if (time < 60) {
    return `${time}分钟`;
  }
  if (time >= 60 && time <= 60 * 24) {
    const hours = parseInt(String(time / 60), 10);
    const mins = time - hours * 60;
    return mins === 0 ? `${hours}小时` : `${hours}小时${mins}分钟`;
  }
  const days = parseInt(String(time / 60 / 24), 10);
  const hours = parseInt(String((time - days * 60 * 24) / 60), 10);
  const mins = time - days * 60 * 24 - hours * 60;
  const arr = [`${days}天`];
  if (hours > 0) {
    arr.push(`${hours}小时`);
  }
  if (mins > 0) {
    arr.push(`${mins}分钟`);
  }
  return arr.join('');
}

// 有权查看并修改所有试卷的uid
export function getSuperTeacherUid() {
  return ['230553', '55549']; // 帆软和传说哥
}

// 检查是否是html字符串
export function checkHtml(htmlStr: string) {
  const reg = /<[^>]+>/g;
  return reg.test(htmlStr);
}

// 判断是否是p系列
export function judgeIsPExam(type: TExamKind) {
  return [
    'fcrp',
    'fcrpd',
    'fcbp',
    'fcbp6',
    'fcap',
    'fcjp',
    'fcp_plugin',
    'fcp_data_analysis',
    'fcp_business_data_analysis',
  ].includes(type);
}

// 创造一个范围
export function range(start: number, end: number) {
  const result = [];
  for (let i = start; i < end; i += 1) {
    result.push(i);
  }
  return result;
}

// cas登录
export function casLogin() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT_ENV !== 'dev') {
    const now_url = window.location.href;
    const withoutHost = now_url.split('fanruan.com')[1];
    const onlyRouter = withoutHost.split('?')[0];
    const referrerUrl = `${process.env.NEXT_PUBLIC_WEBSITE_URL}server/login/cas?redirect=${onlyRouter}`;
    // window.location.href = `${process.env.NEXT_PUBLIC_PASSPORT_URL}login/signin/?referrer=${encodeURIComponent(referrerUrl)}`;
    window.location.href = `${process.env.NEXT_PUBLIC_PASSPORT_URL}cas/login?service=${process.env.NEXT_PUBLIC_WEBSITE_URL}server/login/cas?redirect=${onlyRouter}`;
  }
}

// 登录
export function login({
  loginUrl,
  env,
  testPassportUrl = 'https://passport.fanruan.com',
  prodPassportUrl = 'https://fanruanclub.com',
}: {
  loginUrl?: string;
  env?: 'test' | 'prod';
  prodPassportUrl?: string;
  testPassportUrl?: string;
}) {
  if (loginUrl) {
    window.location.href = loginUrl;
  } else {
    const now_url = window.location.href;
    const passportUrl = env === 'test' ? testPassportUrl : prodPassportUrl;
    const fanruanSplitArray = now_url.split('fanruan.com');
    let onlyRouter = '/';
    if (Array.isArray(fanruanSplitArray) && fanruanSplitArray.length > 1) {
      const withoutHost = fanruanSplitArray[1];
      //eslint-disable-next-line prefer-destructuring
      onlyRouter = withoutHost.split('?')[0];
    }
    window.location.href = `${passportUrl}/cas/login?service=${window.location.origin}/server/login/cas?redirect=${onlyRouter}`;
  }
}

// 头像
export function userAvatar(uid: string, size: 'small' | 'big' = 'small') {
  return `https://bbs.fanruan.com/uc_server/avatar.php?uid=${uid}&size=${size}`;
}

export function delCookie(name: string) {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval !== null)
    document.cookie = `${name}=${cval};expires=${exp.toUTCString()};path=/`;
}

export const generateRandomStr = () => {
  return Math.random().toString(36).slice(-8);
};
