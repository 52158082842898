import {Col, Row, Space} from 'antd';
import React, {ReactElement, useContext} from 'react';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import {delCookie, generateRandomStr, userAvatar} from '@/utils/index';

const HeaderUserPopover: React.FC = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {
    env,
    testPassportUrl,
    prodPassportUrl,
    dropDownMenu,
    uid,
    username,
    onLogout,
  } = NavigationHeaderContextProps;
  const fixedMenuItem: Record<'home' | 'task' | 'class', ReactElement> = {
    home: (
      <div
        className="section-item"
        onClick={() => {
          window.open(`https://bbs.fanruan.com/u/${uid}`);
        }}
      >
        <img
          className="section-item-icon"
          src="https://hwobs.fanruan.com/ui/digit-fe/header/header-user.png"
          alt=""/>
        我的主页
      </div>
    ),
    task: (
      <div
        className="section-item"
        onClick={() => {
          window.open('https://bbs.fanruan.com/task-my.html');
        }}
      >
        <img
          alt=""
          className="section-item-icon"
          src="https://hwobs.fanruan.com/ui/digit-fe/header/header-calendar.png"
        />
        我的任务
      </div>
    ),
    class: (
      <div
        className="section-item"
        onClick={() => {
          window.open('https://edu.fanruan.com/class');
        }}
      >
        <img
          alt=""
          className="section-item-icon"
          src="https://hwobs.fanruan.com/ui/digit-fe/header/header-report.png"
        />
        我的班级
      </div>
    ),
  };
  const createDropDownMenu = () => {
    if (dropDownMenu && Array.isArray(dropDownMenu)) {
      return dropDownMenu.map((item) => {
        if (typeof item === 'string') {
          if (fixedMenuItem[item]) {
            return (
              <Col span={12} key={item}>
                {fixedMenuItem[item]}
              </Col>
            );
          }
          return null;
        }
        if (React.isValidElement(item)) {
          return (
            <Col span={12} key={generateRandomStr()}>
              {item}
            </Col>
          );
        }
        return null;
      });
    }
    return (['home', 'task', 'class'] as ('home' | 'task' | 'class')[]).map(
      (item) => {
        return (
          <Col span={12} key={generateRandomStr()}>
            {fixedMenuItem[item]}
          </Col>
        );
      },
    );
  };
  return (
    <div className="dr-nav-popover-inner">
      <div className="dr-nav-popover-inner-section">
        <div className="avatar-wrapper">
          <div className="detail-wrapper">
            <div className="username-info">{username}</div>
            <Space className="uid-info">uid: {uid}</Space>
            <Space size={40}>
              <a
                href="https://fanruanclub.com/#/info/"
                target="_blank"
                rel="noreferrer"
              >
                个人资料
              </a>
              <a
                href="https://fanruanclub.com/#/info/auth"
                target="_blank"
                rel="noreferrer"
              >
                账号认证
              </a>
            </Space>
          </div>
          <div>
            <img
              alt=""
              className="dr-nav-popover-inner-logo-big"
              src={userAvatar(uid as string)}
            />
          </div>
        </div>
      </div>
      <div className="dr-nav-popover-inner-section">
        <Row>{createDropDownMenu()}</Row>
      </div>
      <div
        className="dr-nav-popover-inner-section"
        onClick={() => {
          if (onLogout) {
            onLogout?.();
          } else {
            const url = env === 'prod' ? prodPassportUrl : testPassportUrl;
            delCookie('fr_token');
            window.location.href = `${url}/login/token/logout?referrer=${window.location.href}`;
          }
        }}
      >
        <div className="section-item">
          <img
            alt=""
            className="section-item-icon"
            src="https://hwobs.fanruan.com/ui/digit-fe/header/header-logout.png"
          />
          退出登录
        </div>
      </div>
    </div>
  );
};
export default HeaderUserPopover;
