import React, {useContext, useEffect, useState} from 'react';
import cx from 'classnames';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import {generateRandomStr} from '@/utils/index';

const HeaderNavItem: React.FC = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {
    navItem = [
      {
        label: '首页',
        href: 'https://home.fanruan.com/',
        isActive: false,
      },
      {
        label: '服务平台',
        href: 'https://service.fanruan.com/',
        isActive: false,
      },
      {
        label: '文档',
        href: 'https://help.fanruan.com/',
        isActive: false,
      },
      {
        label: '学院',
        href: 'https://edu.fanruan.com/',
        isActive: false,
      },
      {
        label: '问答',
        href: 'https://bbs.fanruan.com/wenda',
        isActive: false,
      },
      {
        label: '论坛',
        href: 'https://bbs.fanruan.com/topic',
        isActive: false,
      },
      {
        label: '认证',
        href: 'https://cert.fanruan.com/',
        isActive: false,
      },
      {
        label: '招聘',
        href: 'https://bbs.fanruan.com/jobs',
        isActive: false,
      },
      {
        label: '任务',
        href: 'https://bbs.fanruan.com/task',
        isActive: false,
      },
      {
        label: '市场',
        href: 'https://market.fanruan.com/',
        isActive: false,
      },
    ],
  } = NavigationHeaderContextProps;
  const [data, setData] = useState(navItem);

  useEffect(() => {
    if (Array.isArray(navItem)) {
      setData(navItem);
    }
  }, [navItem]);

  return (
    <div className="dr-nav-item-wrapper">
      {data.map((item) => {
        return (
          <a
            href={item.href}
            className={cx('dr-nav-item', item.isActive && 'active')}
            key={generateRandomStr()}
            rel="noreferrer"
          >
            {item.label}
          </a>
        );
      })}
    </div>
  );
};
export default HeaderNavItem;
