import {BellOutlined} from '@ant-design/icons';
import {useRequest} from 'ahooks';
import {Badge} from 'antd';
import React, {useContext, useState} from 'react';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';

const HeaderUnReadBell: React.FC = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {uid, device} = NavigationHeaderContextProps;
  const [unReadNum, setUnReadNum] = useState();
  useRequest(
    () => {
      return fetch(`https://home.fanruan.com/message/open/api/unread/${uid}`);
    },
    {
      onSuccess: async (data) => {
        if (data) {
          const res = await data.json();
          if (res.code === 200) {
            setUnReadNum(res.data);
          }
        }
      },
    },
  );

  return (
    <div
      className="dr-nav-icon-item"
      onClick={() => {
        window.open('https://home.fanruan.com/message');
      }}
    >
      <div className="icon-wrapper">
        <Badge dot={device === 'mobile'} count={unReadNum} size="small">
          <BellOutlined className="icon"/>
        </Badge>
      </div>
      <div className="icon-wrapper-tips">通知</div>
    </div>
  );
};
export default HeaderUnReadBell;
