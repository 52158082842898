import React from "react";
import Head from "next/head";

type THeo = {
  title?: string;
  description?: string;
  keyword?: string;
}

const Seo:React.FC<THeo> = ({title,description,keyword}) => {
  const websiteTitle = title || process.env.NEXT_PUBLIC_DEFAULT_WEBSITE_TITLE;
  const websiteDescription = description || '帆软认证是帆软凭借十多年行业经验，面向IT、业务人员、学生等群体推出的能力资质认证，证书广受用人单位好评与推荐。';
  const websiteKeyword = keyword || '帆软职业资格认证,帆软认证,报表工程师,BI工程师,报表开发工程师,报表交付工程师,数据分析师,业务分析师,FCA,FCP'
  return (
    <Head>
      <title>{websiteTitle}</title>
      <meta name="description" content={websiteDescription} />
      <meta name="keywords" content={websiteKeyword} />
    </Head>
  )
}
export default Seo;
