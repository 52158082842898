import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic'
import { PageWrapper } from '@/components';
import Banner from '@/page-components/home/Banner';
import Intro from '@/page-components/home/Intro';
import ClassIntro from '@/page-components/home/Class_intro';
import Job from '@/page-components/home/Job';
import { TUserInfoTypes } from '@/types/redux_type';

const DynamicCompany = dynamic(() => import('@/page-components/home/Company'));
const DynamicQa = dynamic(() => import('@/page-components/home/Qa'));
const DynamicRecommend = dynamic(() => import('@/page-components/home/Recommend'));

type THome = {
  userInfo: TUserInfoTypes;
};

const Home: React.FC<THome> = ({ userInfo }) => {
  useEffect(() => {
    console.log('加载环境变量');
    console.log(`环境变量是${process.env.NEXT_PUBLIC_ENVIRONMENT_ENV}`);
  }, []);

  return (
    <PageWrapper>
      <Element name="test1" className="element" key="test1">
        <Banner userInfo={userInfo} />
      </Element>
      <Element
        name="test2"
        className="element"
        key="test2"
        style={{ backgroundColor: 'white' }}
      >
        <Intro />
      </Element>

      <Element name="test3" className="element" key="test3">
        <ClassIntro />
      </Element>

      <Element name="test4" className="element" key="test4">
        <Job />
      </Element>

      <Element name="test5" className="element" key="test5">
        <DynamicCompany />
      </Element>

      <Element name="test6" className="element" key="test6">
        <DynamicQa />
      </Element>

      <Element name="test7" className="element" key="test7">
        <DynamicRecommend />
      </Element>
    </PageWrapper>
  );
};
export default connect((state: { userInfo: TUserInfoTypes }) => {
  return state;
})(Home);
export const getStaticProps = async () => {
  return {
    props: {
      layout: 'master',
      name: '',
    },
  };
};
