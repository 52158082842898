import React from "react";
import cx from 'classnames';
import { useRouter } from 'next/router'
import {TExamCategory} from "@/types";
import ExamArrow from "@/components/ui/exam_arrow";
import styles from './styles.module.less'

type TNavigate = {
  url?:string,
  className?: string;
  type: TExamCategory;
  disabled?: boolean;
  text: string;
}

const Navigate:React.FC<TNavigate> = (
  {
    className,
    type,
    disabled=false,
    text,
    url
  }) => {
  const router = useRouter()
  return (
    <div className={cx(styles.navigate_wrapper,className)} onClick={() => {
      if (!disabled && url){
        router.push(url)
      }
    }}>
      <div className={cx(styles.navigate,disabled ? styles[`${type}_disabled_bg`] : styles[`${type}_bg`])}>
        <p className={styles.text}>{text}</p>
        <div className={styles.info_row}>
          <p className={cx(styles.info_text,styles[`${type}_color`])}>
            {disabled ? <span style={{color:'#8496ab'}}>敬请期待</span> : '了解详情并报名'}
          </p>
          {!disabled && <ExamArrow type={type} className={styles.info_icon}/>}
        </div>
      </div>
    </div>
  )
}
export default Navigate;
