import React from "react";
import useAnimateNumber from "@/components/ui/scrollNumber";

type TBasicNumber = {
  number: number
}

const BasicNumber:React.FC<TBasicNumber> = ({number}) => {
  const [basic_num] = useAnimateNumber(number);
  function numFormat(num:number){
    return num.toString().replace(/\d+/, (n) => { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
        return `${$1  },`;
      });
    });
  }
  return (
    <>
      {numFormat(basic_num)}
    </>
  )
}
export default BasicNumber;
