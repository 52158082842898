import React, {CSSProperties} from "react";
import {Media} from "@/constants/media_query";

type TPcComponentWrapper = {
  className?: string;
  style?: CSSProperties
}

const PcComponentWrapper: React.FC<TPcComponentWrapper> = ({style, children, className}) => {
  return (
    <Media greaterThan="md" style={style} className={className}>{children}</Media>
  )
}
export default PcComponentWrapper;
