import React, {useEffect} from 'react';
import Script from 'next/script'
import {ConfigProvider, message} from 'antd';
import {compose} from 'redux';
import NProgress from 'nprogress';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import {HelmetProvider} from 'react-helmet-async';
import {Router, useRouter} from 'next/router';
import moment from 'moment';
import Head from 'next/head';
import {Cookies, CookiesProvider} from 'react-cookie';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {ErrorBoundary} from '@/components';
import {MasterLayout, TestLayout} from '@/layouts';
import {DISABLE_SSR_TRANSITION} from '@/pages/_document';
import {casLogin, getCookie, isServer, removeCookie, setCookie} from '@/utils';
import {ILayout} from '@/interfaces/jsx.interface';
import {MediaContextProvider} from '@/constants/media_query';
import {CLEARCOOKIE, LOGOUT, SETUSERINFO} from '@/actions/types';
import {apiGetSid, apiGetUserInfo} from '@/services/user';
import Footer from '@/layouts/Footer';
import Seo from "@/components/Seo/index";
import {TUserInfoTypes} from "@/types/redux_type";
import FineClubModule from "@/utils/FineClubModule";
import Header from '../layouts/Header/index';
import {wrapperStore} from '../../store';

require('@/styles/global.less');
require('node_modules/@digit-fe/digit-component/dist/umd/digit-component.min.css')

export interface ICustomApp {
  Component: React.FC & {
    getLayout: any;
  };
  cookies: any;
  pageProps: {
    title?: string;
    description?: string;
    footerInfo?: {
      havePcFooter?: boolean;
      haveMobileFooter?: boolean;
    }
    haveFooter?: boolean;
    layout?: ILayout;
    name?: string;
    isDefaultHeader?: boolean;
  };
  routeProps: Router;
  err?: Error;
}

function CustomApp(props: ICustomApp) {
  const isBrowser = typeof window !== 'undefined';
  const router = useRouter();
  const dispatch = useDispatch();
  const userInfo = useSelector<{ userInfo: TUserInfoTypes }>((state) => {
    return state.userInfo;
  }) as { uid?: string }

  const userLogin = async (code: string) => {
    // if (code) {
    //   try {
    //     const loginInfo = await login(code) as {
    //       code: number;
    //       success: true;
    //       data: {
    //         access_token: string;
    //         expires_time: number;
    //         refresh_token: string;
    //         client: {
    //           appid: string;
    //           uid: number;
    //           username: string;
    //         }
    //       }
    //     };
    //     if (loginInfo.code === 200) {
    //       const appid = loginInfo.data?.client?.appid;
    //       const digit_access_token = loginInfo.data?.access_token;
    //       const expires_time = loginInfo.data?.expires_time;
    //       const digit_refresh_token = loginInfo.data?.refresh_token;
    //       const uid = loginInfo.data?.client?.uid;
    //       setCookie('fr_username', loginInfo.data?.client?.username);
    //       setCookie('fr_uid', uid);
    //       setCookie('digit_access_token', digit_access_token);
    //       setCookie('digit_expires_time', expires_time);
    //       setCookie('digit_refresh_token', digit_refresh_token);
    //       setCookie('fr_appid', appid);
    //       dispatch({type: SETUSERINFO, payload: {uid}})
    //     } else {
    //       dispatch({type: CLEARCOOKIE, payload: null});
    //     }
    //   } catch (e) {
    //     dispatch({type: CLEARCOOKIE, payload: null});
    //   }
    // }
  };
  const doRefreshShequToken = async (params: {
    uid: string;
    accessToken: string;
    appid: string;
    expiresTime: number;
    refreshToken: string;
  }) => {
    const {uid, accessToken, appid, expiresTime, refreshToken} = params;

    function refreshShequToken(refresh_token: string) {
      return axios({
        url: `${process.env.NEXT_PUBLIC_PASSPORT_URL}/v1/token/refresh/?appid=${appid}&uid=${uid}&refresh_token=${refresh_token}`,
        method: 'get',
      });
    }

    if (uid && accessToken && typeof window !== 'undefined') {
      let token = window.btoa(`${appid}.${accessToken}.${uid}`);
      if (moment().isAfter(moment(expiresTime * 1000 - 5 * 60 * 1000))) {
        try {
          const data = await refreshShequToken(refreshToken);
          if (data.data.code === 200) {
            console.log('数字平台刷新token成功');
            const {access_token, expires_time, refresh_token} =
              data.data.data;
            setCookie('fr_access_token', access_token);
            setCookie('fr_refresh_token', refresh_token);
            setCookie('fr_expires_time', expires_time);
            token = window.btoa(`${appid}.${accessToken}.${uid}`);
          } else {
            dispatch({type: CLEARCOOKIE, payload: null});
          }
        } catch (e) {
          dispatch({type: CLEARCOOKIE, payload: null});
        }
      }
    }
  };
  const fetchUserInfo = async () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT_ENV === 'dev') {
      // setCookie('fr_uid', process.env.NEXT_PUBLIC_UID);
      // setCookie('fr_appid', 'shequapp');
      // setCookie(
      //   'fr_refresh_token',
      //   process.env.NEXT_PUBLIC_REFRESH_TOKEN,
      // );
      // setCookie(
      //   'fr_access_token',
      //   process.env.NEXT_PUBLIC_ACCESS_TOKEN,
      // );

      // const digit_refresh_token = getCookie('digit_refresh_token');
      // const digit_access_token = getCookie('digit_access_token');
      // const digit_expires_time = getCookie('digit_expires_time');
      // if (username && uid && digit_access_token && typeof window !== "undefined") {
      //   if (moment().isAfter(moment(digit_expires_time * 1000 - 5 * 60 * 1000))) {
      //     try {
      //       const loginInfo = await digitRefreshToken({uid, username, refreshToken: digit_refresh_token}) as {
      //         code: number;
      //         success: true;
      //         data: {
      //           access_token: string;
      //           expires_time: number;
      //           refresh_token: string;
      //           client: {
      //             appid: string;
      //             uid: number;
      //             username: string;
      //           }
      //         }
      //       }
      //       if (loginInfo.code === 200) {
      //         console.log('刷新数字平台token成功');
      //         const new_digit_access_token = loginInfo.data?.access_token;
      //         const new_expires_time = loginInfo.data?.expires_time;
      //         const new_digit_refresh_token = loginInfo.data?.refresh_token;
      //         setCookie('digit_access_token', new_digit_access_token);
      //         setCookie('digit_expires_time', new_expires_time);
      //         setCookie('digit_refresh_token', new_digit_refresh_token);
      //       } else {
      //         dispatch({type: CLEARCOOKIE, payload: null});
      //       }
      //     } catch (e) {
      //       dispatch({type: CLEARCOOKIE, payload: null});
      //     }
      //   }
      // } else if (code) {
      //   await userLogin(code);
      // }
    } else {
      // username = getCookie('fr_username');
      // const appid = getCookie('fr_appid');
      // const code = getCookie('fr_id_auth');
      // const digit_refresh_token = getCookie('digit_refresh_token');
      // const digit_access_token = getCookie('digit_access_token');
      // const digit_expires_time = getCookie('digit_expires_time');
      // if (username && uid && digit_access_token) {
      //   if (moment().isAfter(moment(digit_expires_time * 1000 - 5 * 60 * 1000))) {
      //     const loginInfo = await digitRefreshToken({uid, username, refreshToken: digit_refresh_token}) as {
      //       code: number;
      //       success: true;
      //       data: {
      //         access_token: string;
      //         expires_time: number;
      //         refresh_token: string;
      //         client: {
      //           appid: string;
      //           uid: number;
      //           username: string;
      //         }
      //       }
      //     }
      //     if (loginInfo.code === 200) {
      //       console.log('刷新数字平台令牌成功')
      //       const new_digit_access_token = loginInfo.data?.access_token;
      //       const new_expires_time = loginInfo.data?.expires_time;
      //       const new_digit_refresh_token = loginInfo.data?.refresh_token;
      //       const new_token = window.btoa(
      //         `${appid}.${digit_access_token}.${uid}`,
      //       );
      //       setCookie('digit_access_token', new_digit_access_token);
      //       setCookie('digit_expires_time', new_expires_time);
      //       setCookie('digit_refresh_token', new_digit_refresh_token);
      //       setCookie('digit_token', new_token);
      //     } else {
      //       console.log('刷新失败，尝试重新登录');
      //       if (code) {
      //         await userLogin(code);
      //       } else {
      //         dispatch({type: CLEARCOOKIE, payload: null});
      //       }
      //     }
      //   }
      // } else if (code) {
      //   await userLogin(code);
      // }
    }
    // await doRefreshShequToken({
    //   uid: getCookie('fr_uid'),
    //   appid: getCookie('fr_appid'),
    //   accessToken: getCookie('fr_access_token'),
    //   refreshToken: getCookie('fr_refresh_token'),
    //   expiresTime: getCookie('fr_expires_time')
    // })
  };
  // 是否是默认的header
  const isDefaultHeader =
    props.pageProps?.isDefaultHeader === undefined
      ? true
      : props.pageProps?.isDefaultHeader;
  const haveFooter = props.pageProps?.haveFooter === undefined;
  const footerInfo = props.pageProps?.footerInfo || {
    havePcFooter: true,
    haveMobileFooter: true,
  }
  const layout =
    props.pageProps?.layout === undefined ? 'master' : props.pageProps?.layout;
  const avoidCssAnimationFlashing = () => {
    if (!isServer()) {
      const disableTransitionDom = document.getElementById(
        DISABLE_SSR_TRANSITION,
      );

      if (disableTransitionDom) disableTransitionDom.remove();
    }
    moment.locale('zh-cn', {
      months:
        '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split(
          '_',
        ),
      monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split(
        '_',
      ),
      weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
      weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
      weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY年MM月DD日',
        LLL: 'YYYY年MM月DD日Ah点mm分',
        LLLL: 'YYYY年MM月DD日ddddAh点mm分',
        l: 'YYYY-M-D',
        ll: 'YYYY年M月D日',
        lll: 'YYYY年M月D日 HH:mm',
        llll: 'YYYY年M月D日dddd HH:mm',
      },
      meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
      calendar: {
        sameDay: '[今天]LT',
        nextDay: '[明天]LT',
        nextWeek: '[下]ddddLT',
        lastDay: '[昨天]LT',
        lastWeek: '[上]ddddLT',
        sameElse: 'L',
      },
      dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
      relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年',
      },
      week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
      },
    });
  };

  let layoutDom = null;

  if (layout === 'master') {
    layoutDom = (
      <MasterLayout
        haveFooter={haveFooter}
        mainComp={props.Component}
        routeProps={props.routeProps}
        pageProps={props.pageProps}
      />
    );
  }

  if (layout === 'test') {
    layoutDom = (
      <TestLayout
        mainComp={props.Component}
        routeProps={props.routeProps}
        pageProps={props.pageProps}
      />
    );
  }
  // 获取用户基本信息
  const getUserInfoApi = async (isPassportLogin = false) => {
    // const sid = getEnvParams()
    console.log('redux里的userInfo是');
    console.log(userInfo);
    const fr_token = getCookie('fr_token');
    try {
      const res = await apiGetUserInfo();
      if (res.code === 200 && res.success) {
        if (res.data !== null) {
          const {isStudent, isRealname, isAdmin, isSupplierState} = res.data
          setCookie('uid', res.data.uid);
          dispatch({
            type: SETUSERINFO, payload: {
              ...res.data,
              init: true,
              isPassStudentAuthentication: isStudent,
              isPassRealnameAuthentication: isRealname,
              isOrganizationAdmin: isAdmin,
              isSupplierState
            }
          });
        } else if (isPassportLogin) {
          // 在监听到iframe传来消息并且用户信息获取为null时再去跳转一下通行证
          casLogin();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    message.config({
      maxCount: 1,
    });
    const initData = async (isPassportLogin = false) => {
      const searchStr = new URLSearchParams(window.location.search);
      const sidArray = searchStr.getAll("sid");
      if (sidArray.length > 0) {
        const sid = sidArray[sidArray.length - 1];
        await apiGetSid(sid);
      }
      await getUserInfoApi(isPassportLogin);
      await fetchUserInfo();
    };
    // initData();

    avoidCssAnimationFlashing();
    if (router?.events?.on) {
      router.events.on('routeChangeStart', async (data) => {
        console.log('routeChangeStart');
        console.log(data);
        if (data !== '/' && data.indexOf('/?sid=') === -1) {
          await fetchUserInfo();
        }
        /**
         * 如何拦截一个路径请求
         * */
        // if(data === '/exam'){
        //   router.events.emit('routeChangeError');
        //   router.replace(router, router.asPath, { shallow: true });
        //   throw new Error('自定义错误');
        // }
        NProgress.start();
      });
      router.events.on('routeChangeComplete', async () => {
        NProgress.done();
      });
      router.events.on('routeChangeError', () => {
        NProgress.done();
      });
    }
    initData();

    function getLoginUid() {
      return getCookie('fr_uid') || 0;
    }

    // fineSDK.config({ app:"cert", getLoginUid, debug: false, dev: process.env.NEXT_PUBLIC_ENVIRONMENT_ENV === 'dev' });
    // 监听登录
    function callback(data: { uid: string }) {
      console.info('callback_data', data);
      if (data.uid) {
        initData(true);
        //if (!getCookie('fr_token')) {
        //  casLogin();
        //} else {
        //  initData(true);
        //}
      } else {
        // 退出登录
        console.info('监听到退出登录');
        removeCookie('fr_token');
        removeCookie('fr_token_test');
        removeCookie('uid');
        dispatch({type: LOGOUT, payload: undefined});
      }
      //if(!get_ticket && data.uid && isLogin < 1){
      //  console.log('跳转到通行证登录',isLogin);
      //}
      // else{
      //   if(isLogin > 1 && process.env.VUE_APP_ENV != 'development'){
      //     console.log('callback','del_token');
      //     console.log('isLogin',isLogin);
      //     store.commit('del_token');
      //     setTimeout(function() {
      //       window.location.replace(process.env.VUE_APP_EDU_URL+to.path);
      //     }, 250);
      //   }
      // }
    }

    // fineSDK.onLoginChange(callback);

    const fineModule = new FineClubModule({debug: false, getLoginUid});
    fineModule.onLoginChange(callback);
  }, []);
  return (
    <HelmetProvider>
      <CookiesProvider
        cookies={isBrowser ? undefined : new Cookies(props.cookies)}
      >
        <Seo description={props.pageProps.description} title={props.pageProps.title} keyword={props.pageProps.title}/>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
          />
          <Script src="/huawei.js" type="application/javascript"/>
          <script dangerouslySetInnerHTML={{
            __html: `var _hmt = _hmt || [];
                    (function()
                    { var hm = document.createElement("script"); hm.src = "https://hm.baidu.com/hm.js?b8c27cd332c57f65e45b143be5268c5a"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(hm, s); }
                    )();`
          }}/>
        </Head>
        <MediaContextProvider>
          {isDefaultHeader && <Header/>}
          <ErrorBoundary>
            <ConfigProvider locale={zh_CN}>
              {layoutDom || <span/>}
            </ConfigProvider>
          </ErrorBoundary>
          {haveFooter &&
            <Footer havePcFooter={footerInfo.havePcFooter} haveMobileFooter={footerInfo.haveMobileFooter}/>}
          {/* </IconContext.Provider> */}
        </MediaContextProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
}

export default compose(wrapperStore.withRedux)(CustomApp);
