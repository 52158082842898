import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createWrapper, Context } from "next-redux-wrapper";
import reducer from "./src/reducers/index";

export const initStore = (context: Context) => {
  return createStore(reducer, applyMiddleware(thunk));
};
// @ts-ignore
export const wrapperStore = createWrapper(initStore, { debug: true });
