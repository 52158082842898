import React from 'react';
import {Button, Result} from 'antd';

interface IProps {}

interface IState {
  hasError: boolean;
  // errorInfo: any;
}


export class ErrorBoundary extends React.Component<IProps, IState> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
      // errorInfo: {},
    };
  }

  componentDidCatch(err: Error) {
    // this.setState({ errorInfo: err.message });
  }

  onRefresh = () => {
    window.location.href = '/';
    // window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="页面发生错误"
          extra={<Button type="primary" onClick={() => {
            window.location.reload();
          }}>刷新后重试</Button>}
        />
      );
    }

    return this.props.children;
  }
}
