import { CloseOutlined } from '@ant-design/icons';
import { Drawer, DrawerProps, Space } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

type IHeaderDrawer = {
  button: React.ReactNode;
};

const HeaderDrawer = forwardRef<any, DrawerProps & IHeaderDrawer>(
  (props, ref) => {
    const { children, button, placement = 'left' } = props;
    const [visible, setVisible] = useState(false);
    useImperativeHandle(ref, () => {
      return {
        closeModal() {
          setVisible(false);
        },
      };
    });
    return (
      <div>
        <div
          onClick={() => {
            setVisible(true);
          }}
        >
          {button}
        </div>
        <Drawer
          className="dr-nav-drawer"
          width={300}
          title={
            <Space size={12}>
              <CloseOutlined
                onClick={() => {
                  setVisible(false);
                }}
              />
              <div className="dr-nav-logo">
                <img
                  src="https://hwobs.fanruan.com/ui/digit-fe/header/fine_logo_dynamic.gif"
                  alt="fine_logo"
                />
              </div>
            </Space>
          }
          placement={placement}
          closable={false}
          onClose={() => {
            setVisible(false);
          }}
          open={visible}
          key="test"
        >
          {children}
        </Drawer>
      </div>
    );
  },
);
export default HeaderDrawer;
