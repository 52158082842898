import React from "react";
import cx from "classnames";
import {RightOutlined} from "@ant-design/icons";
import {TExamCategory} from "@/types";
import styles from "./styles.module.less";

type TExamArrow = {
  type?: TExamCategory | 'normal';
  className?: string;
}

const ExamArrow:React.FC<TExamArrow> = ({type='normal',className}) => {
  return (
    <div className={cx(styles.exam_arrow_wrapper)}>
      <div className={cx(styles.icon_wrapper,styles[`${type}_exam_arrow`], className)}>
        <RightOutlined />
      </div>
    </div>
  )
}
export default ExamArrow;
