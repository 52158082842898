import React from "react";
import {Collapse} from "antd";
import QaItem from "@/page-components/home/Qa/qa_item_pc";
import MobileComponentWrapper from "@/components/MobileComponentWrapper";
import PcComponentWrapper from "@/components/PcComponentWrapper";
import styles from './styles.module.less';

const {Panel} = Collapse;

const Qa:React.FC = () => {
  const data = [{
    id:0,
    question:'为什么需要实名认证',
    answer:'认证证书会绑定你的身份证号存储在系统中，所以需要通过实名认证进行绑定，若身份证号有误，将会影响查询。'
  }, {
    id:1,
    question:'线上考试 or 线下考试？',
    answer:'目前推出的认证考试都是线上的。 其中FCA入门认证为系统自动判卷、生成电子证书；FCP资深认证为人工阅卷。'
  },{
    id:2,
    question:'报考费多少？没通过，可以补考吗',
    answer:'FCA入门认证是免费考试；FCP资深认证提供1次补考机会（有效期6个月内，可自行选择补考时间）， 报考费见各认证报考页。'
  },{
    id:3,
    question:'考试时间是多久?',
    answer:'FCA入门认证：35分钟，FCP资深认证：报表交付工程师4天，其余均为2天时间'
  },{
    id:4,
    question:'可以开发票吗？',
    answer:"只要你使用微信或者支付宝付款，都可以自助开票，（<a href='https://fanruanclub.com/#/orders/' target='_blank'>戳我勾选付款订单开票</a>）"
  },{
    id:5,
    question:'有纸质证书吗？',
    answer:'官方目前只提供电子证书，纸质证书需要您自行打印。'
  },{
    id:6,
    question:'已报名FCP资深认证，还能重新选择考试时间吗？',
    answer:'不能，一旦报名成功，确定认证时间，则不支持更换时间，报名请慎重！'
  },{
    id:7,
    question:'其他常见问题',
    answer:'<a href="https://bbs.fanruan.com/thread-134884-1-1.html">点击查看其他常见问题汇总</a>'
  }]
  return (
    <div className={styles.qa_wrapper}>
      <div className={styles.title}>常见问题</div>
      <MobileComponentWrapper>
        <Collapse bordered={false} onChange={() => {}} expandIconPosition="right">
          {data.map((item, index) => {
            return (
              <Panel key={item.id} header={<div className={styles.item_title}>{index+1}、{item.question}</div>} >
                <p className={styles.item_text} dangerouslySetInnerHTML={{__html:item.answer}} />
              </Panel>
            )
          })}
        </Collapse>
      </MobileComponentWrapper>
      <PcComponentWrapper>
        <div className={styles.qa_wrapper_body}>
          {data.map((item) => {
            return (
              <QaItem key={item.id} number={item.id + 1} question={item.question} answer={item.answer} />
            )
          })}
        </div>
      </PcComponentWrapper>
    </div>
  )
}
export default Qa;
