import React from 'react';
import {connect, useDispatch} from "react-redux";
import {useRouter} from "next/router";
import {message} from "antd";
import {TUserInfoTypes} from "@/types/redux_type";
import NavigationHeader from "@/components/NavigationHeader/index";
import {removeCookie} from "@/utils/index";
import {CLEARCOOKIE} from "@/actions/types";
import styles from './style.module.less';

type THeader = {
  userInfo: TUserInfoTypes
}

const Header: React.FC<THeader> = ({userInfo}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  return (
    <div className={styles.header}>
      <NavigationHeader
        env={process.env.NEXT_PUBLIC_ENVIRONMENT_ENV === 'prod' ? 'prod' : 'test'}
        siteName="帆软社区"
        onLogout={() => {
          removeCookie('fr_token');
          message.success('您已成功退出登录');
          window.location.href = `${process.env.NEXT_PUBLIC_PASSPORT_URL}login/token/logout?referrer=${window.location.href}`;
          dispatch({type: CLEARCOOKIE, payload: null});
        }}
        onSiteNameClick={() => {
          window.location.href = "https://home.fanruan.com"
        }}
        uid={userInfo.uid}
        onLogoClick={() => {
          window.location.href = "https://home.fanruan.com/"
        }}
        username={userInfo.username}
      />
    </div>
  )
}
export default connect((state: {
  userInfo: TUserInfoTypes
}) => {
  return state;
})(Header);
