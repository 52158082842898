import React from "react";
import cx from 'classnames';
import MobileComponentWrapper from "@/components/MobileComponentWrapper";
import PcComponentWrapper from "@/components/PcComponentWrapper";
import styles from './styles.module.less';

type TItem = {
  isLast?:boolean
  isOdd?: boolean
  item:{
    id:number;
    cover:string;
    title:string;
    url:string;
  }
}

const Item:React.FC<TItem> = ({isOdd=false,isLast=false,item}) => {
  return (
    <>
      <MobileComponentWrapper>
        <div className={cx(styles.recommend_item,isOdd && styles.recommend_item_odd)}>
          <img src={item.cover} className={styles.cover} alt="" />
          <div className={styles.recommend_item_body}>
            <p className={styles.recommend_item_title}>{item.title}</p>
          </div>
        </div>
      </MobileComponentWrapper>
      <PcComponentWrapper>
        <a rel="noreferrer" href={item.url} target="_blank" className={styles.recommend_item}>
          <img src={item.cover} className={styles.cover} alt="" />
          <div className={styles.recommend_item_body}>
            <p className={styles.recommend_item_title}>{item.title}</p>
          </div>
        </a>
      </PcComponentWrapper>
    </>
  )
}
export default Item;
