import cx from 'classnames';
import React, {useContext} from 'react';
import {Space} from "antd";
import DivisionLine from '@/components/DivisionLine';
import HeaderInput from '@/components/NavigationHeader/HeaderInput';
import HeaderLogin from '@/components/NavigationHeader/HeaderLogin';
import {NavigationHeaderContext} from '@/components/NavigationHeader/NavigationHeaderContext';
import HeaderNavItem from "@/components/NavigationHeader/HeaderNavItem";

const PcHeader = () => {
  const NavigationHeaderContextProps = useContext(NavigationHeaderContext);
  const {
    activeMenuTitleOnClick,
    onSiteNameClick,
    siteName,
    activeMenuTitle,
    onLogoClick,
  } = NavigationHeaderContextProps;
  return (
    <div className="dr-nav">
      <DivisionLine gap={8} lineClassName="dr-nav-line">
        <div
          className={cx('dr-nav-logo', onLogoClick && 'dr-nav-click')}
          onClick={() => {
            onLogoClick?.();
          }}
        >
          <img
            alt="logo"
            src="https://hwobs.fanruan.com/ui/digit-fe/header/fine_logo_dynamic.gif"
          />
        </div>
        <Space size={32}>
          <div
            className={cx(
              'dr-nav-title',
              (onSiteNameClick || activeMenuTitleOnClick) && 'dr-nav-click',
            )}
            onClick={(e) => {
              e.stopPropagation();
              onSiteNameClick?.();
              if (!siteName) {
                activeMenuTitleOnClick?.();
              }
            }}
          >
            {siteName || activeMenuTitle}
          </div>
          <HeaderNavItem/>
        </Space>
      </DivisionLine>

      <HeaderInput/>

      <HeaderLogin/>
    </div>
  );
};
export default PcHeader;
