import React from "react";
import {Space, Row, Col} from "antd";
import {RightOutlined} from "@ant-design/icons";
import Item from "@/page-components/home/Recommend/item";
import styles from './styles.module.less';

const Recommend:React.FC = () => {
  const data = [{
    id:0,
    cover:'/img/pc_article1.png',
    title:'帆软社区“数据人才库”计划发布！通过资深认证一定要看！',
    url:'https://bbs.fanruan.com/thread-135004-1-1.html',
  },{
    id:1,
    cover:'/img/pc_article2.png',
    title:'数据分析不可怕，学好BI走天下——FCBP认证考试分享',
    url:'https://bbs.fanruan.com/thread-125554-1-1.html',
  },{
    id:2,
    cover:'/img/pc_article3.png',
    title:'沪漂5年：我是如何从职场失意，走向皮实的人生？',
    url:'https://bbs.fanruan.com/thread-125870-1-1.html',
  },{
    id:3,
    cover:'/img/pc_article4.png',
    title:'为什么要报考帆软认证？这就是答案！',
    url:'https://bbs.fanruan.com/thread-125276-1-1.html',
  }]
  return (
    <div className={styles.recommend_wrapper}>
      <div className={styles.recommend}>
        <div className={styles.title}>推荐阅读</div>
        <Row className={styles.row_wrapper}>
          {data.map((item,index) => {
            return (
              <Col xl={6} sm={12} xs={24} key={item.id}>
                <Item isOdd={(index+1)%2===0} isLast={index === data.length-1} item={item}/>
              </Col>
            )
          })}
        </Row>
        <div className={styles.check_more_wrapper}>
          <a rel="noreferrer" target="_blank" href="https://bbs.fanruan.com/topic">
            <Space className={styles.check_more} size={0}>
              <span>查看更多</span>
              <RightOutlined />
            </Space>
          </a>
        </div>
      </div>
    </div>
  )
}
export default Recommend;
