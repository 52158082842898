import React from "react";
import {Row, Col} from 'antd';
import {RightOutlined} from "@ant-design/icons";
import JobList from "@/components/JobList";
import styles from './styles.module.less';


const Job:React.FC = () => {
  const renderLogo = () => {
    const logoArray = Array.from({length: 16});
    const result: JSX.Element[] = [];
    let temp: any = [];
    logoArray.forEach((item, index) => {
      if ((index+1)%4 === 0){
        if (temp.length !==0){
          temp.push(
            <Col className={styles.logo_item} key={Math.random()}>
              <img src={`/img/index_logo/${index}.png`} alt="logo" />
            </Col>
          );
          result.push(<Row key={Math.random()} className={styles.logo_item_row}>{temp}</Row>);
          temp = [];
        }
      } else {
        temp.push(
          <Col key={Math.random()} className={styles.logo_item}>
            <img src={`/img/index_logo/${index}.png`} alt="logo" />
          </Col>
        )
      }
    })
    if (temp.length !==0){
      result.push(<Row key={Math.random()} className={styles.logo_item_row}>{temp}</Row>);
    }
    return result;
  }
  return (
    <div className={styles.job}>
      <p className={styles.title}>数据人才库计划</p>
      <div className={styles.job_link_btn_wrapper}>
        <a rel="noreferrer" className={styles.job_link_btn} href="https://bbs.fanruan.com/thread-135004-1-1.html" target="_blank">
          了解详情
          <RightOutlined />
        </a>
      </div>
      <div className={styles.job_area}>
        <div className={styles.job_area_left}>
          <p className={styles.job_title}>合作企业</p>
          <h3 className={styles.job_subtitle}>与 5000+ 企业建立合作，通过帆软职业认证可获得高薪工作机会</h3>
          {renderLogo()}
        </div>
        <JobList className={styles.job_area_wrapper}/>
      </div>
    </div>
  )
}
export default Job
