import React, {useState} from 'react';
import cx from 'classnames';
import {Button, Space} from "antd";
import {useRequest} from "ahooks";
import Link from "next/link";
import styles from "@/page-components/home/Banner/styles.module.less";
import {apiGetBasicData} from "@/services/home";
import BasicNumber from "@/page-components/home/Banner/basic_number";

type TPcBanner = {
  isTeacher?: boolean,
}
const PCBanner:React.FC<TPcBanner> = ({isTeacher=false}) => {
  const [data, setData] = useState({
    basic_num:-1,
    professional_num:-1,
    resume_num:-1,
  });
  useRequest(() => {
    return apiGetBasicData()as Promise<{
      code: number;
      success: boolean;
      data:{
        professional_num:number;
        resume_num:number;
        basic_num:0;
      }
    }>
  },{
    onSuccess:(res) => {
      if (res.code === 200){
        setData(res.data)
      }
    }
  })
  return (
    <div className={styles.pc_banner_wrapper}>
      <div className={styles.pc_banner}>
        <div>
          <h3 className={styles.title}>帆软职业资格认证</h3>
          <h3 className={styles.subtitle}>通过认证可以充分展示你的知识和技能，充实你的职业生涯</h3>
          <Space size={16} className={styles.btn_wrapper}>
            <Button type="primary" className={styles.btn}>
              <a href="/verify">证书&成绩查询</a>
            </Button>
            {isTeacher && (
              <Button className={cx(styles.btn,styles.simple_btn)}>
                <Link href="/exam/teacher">阅卷入口</Link>
              </Button>
            )}
          </Space>
        </div>
        <div className={styles.pc_banner_wrapper_right}>
          <div className={styles.item}>
            <h3 className={styles.item_title}>
              {data.basic_num >= 0 ? <BasicNumber number={data.basic_num}/> : 0}
              <span className={styles.item_subtitle}>人</span>
            </h3>
            <h3 className={styles.item_subtitle}>
              已通过入门级认证
            </h3>
          </div>
          <div className={styles.item}>
            <h3 className={styles.item_title}>
              {data.professional_num >= 0 ? <BasicNumber number={data.professional_num}/> : 0}
              <span className={styles.item_subtitle}>人</span>
            </h3>
            <h3 className={styles.item_subtitle}>
              已通过资深级认证
            </h3>
          </div>
          <div className={styles.item}>
            <h3 className={styles.item_title}>
              {data.resume_num >= 0 ? <BasicNumber number={data.resume_num}/> : 0}
              <span className={styles.item_subtitle}>人</span>
            </h3>
            <h3 className={styles.item_subtitle}>
              已加入数据人才库
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PCBanner;
