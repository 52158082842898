import React from 'react';
import {Button} from "antd";
import cx from 'classnames';
import styles from './styles.module.less';

const MobileBanner:React.FC = () => {
  return (
    <div>
      <h3 className={styles.title}>帆软职业资格认证</h3>
      <h3 className={styles.subtitle}>通过认证可以充分展示你的知识和技能，充实你的职业生涯</h3>
      <Button type="primary" className={cx(styles.btn,styles.primary_btn)}>
        <a href="/verify">证书&成绩查询</a>
      </Button>
    </div>
  )
}
export default MobileBanner;
