import React from "react";
import styles from './styles.module.less';

const Panel:React.FC = () => {
  return (
    <div className={styles.panel}>
      <p className={styles.title}>认证考试咨询</p>
      <img src="/img/mia_qrcode.png" className={styles.qrcode} alt="" />
      <div className={styles.footer}>
        <p>扫码加微信或</p>
        <a rel="noreferrer" target="_blank" href="https://bbs.fanruan.com/home.php?mod=space&do=pm&subop=view&touid=243717#last">私信TA</a>
      </div>
    </div>
  )
}
export default Panel;
