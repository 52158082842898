import React, {useEffect, useRef} from "react";
import styles from "@/page-components/home/Company/styles.module.less";

type TPCScroll = {
  data: any[]
}

const PCScroll:React.FC<TPCScroll> = ({data}) => {
  const contentRef = useRef(null);
  const wrapperRef = useRef(null);
  // 开始滚动函数
  function roll(t: number | undefined,wrapper: HTMLElement,content:HTMLElement) {
    // 开始无滚动时设为0
    const newWrapper = wrapper;
    newWrapper.scrollTop = 0;
    // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
    function rollStart(){
      if (wrapper.scrollTop >= content.scrollHeight / 2) {
        newWrapper.scrollTop = 0;
      } else {
        newWrapper.scrollTop+=1;
      }
    }
    let timer: any = setInterval(rollStart, t);

    // 鼠标移入div时暂停滚动
    newWrapper.onmouseover = function() {
      clearInterval(timer);
      timer = null
    };

    // 鼠标移出div后继续滚动
    newWrapper.onmouseout = function() {
      timer = setInterval(rollStart, t);
    }
  }

  useEffect(() => {
    if (contentRef.current && wrapperRef.current){
      roll(50,wrapperRef.current,contentRef.current);
    }
  }, []);


  return (
    <div className={styles.scroll_wrapper} ref={wrapperRef}>
      <div className={styles.text_area} ref={contentRef}>
        {[...data,...data].map((item, index) => {
          return (
            <div className={styles.text_body} key={Math.random()}>
              <div className={styles.text}>{item.text}</div>
              <div className={styles.company_name}>{item.name}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default PCScroll;
