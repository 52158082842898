import {createContext, ReactElement} from 'react';
import {INavigationHeaderNavItem} from "@digit-fe/digit-component/dist/NavigationHeader/index";
import {INavigationHeaderMenuItem} from "@/types/component";

export const NavigationHeaderContext = createContext<{
  uid?: string | number;
  ref?: any;
  username?: string;
  siteName?: string;
  env?: 'test' | 'prod';
  testPassportUrl?: string;
  prodPassportUrl?: string;
  onLogout?: () => void;
  onLogin?: () => void;
  onSearch?: (text: string) => void;
  device?: 'pc' | 'mobile';
  onLogoClick?: () => void;
  rightMenu?: ('user' | 'bell' | 'account' | ReactElement)[];
  menu?: INavigationHeaderMenuItem[];
  onMenuClick?: (data: INavigationHeaderMenuItem) => void;
  onSiteNameClick?: () => void;
  activeMenuId?: string;
  activeMenuTitle?: string;
  activeMenuTitleOnClick?: () => void;
  dropDownMenu?: ('home' | 'task' | 'class' | ReactElement)[];
  searchComponent?: false | ReactElement;
  navItem?: INavigationHeaderNavItem[];
}>({
  device: 'pc',
});
