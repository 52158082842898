import React from 'react';
import cx from 'classnames';
import { Collapse, Space } from 'antd';
import Link from 'next/link';
import styles from '@/page-components/home/Class_intro/styles.module.less';

const { Panel } = Collapse;

const ClassIntroMobile: React.FC = () => {
  return (
    <>
      <div className={styles.head}>
        <div className={styles.title}>认证体系</div>
        <p className={styles.subtitle}>
          面向学生、数据分析师、报表工程师等人群，专业技术领域内的技能认证
        </p>
      </div>
      <div className={styles.collapse_wrapper_outer}>
        <div className={styles.collapse_wrapper}>
          <Collapse
            expandIconPosition="right"
            defaultActiveKey={['1']}
            onChange={(data) => {
              console.log(data);
            }}
          >
            <Panel
              header={
                <div>
                  <div className={styles.collapse_header_com}>
                    <div className={styles.collapse_header_title}>入门认证</div>
                    <div className={cx(styles.collapse_header_tag, styles.fca)}>
                      FCA
                    </div>
                  </div>
                  <p className={styles.collapse_header_subtitle}>
                    具备基本的理论知识和工具使用能力
                  </p>
                </div>
              }
              key="1"
              className={styles.collapse_header}
            >
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCRA_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCA-FineReport（原FCRA）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCBA_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCA-FineBI（原FCBA）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCAA_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCA-业务分析理论（原FCAA）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCJA_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCA-简道云（原FCJA）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link
                href={`/detail/${process.env.NEXT_PUBLIC_FCA_SHUZHINIAO_CERTID}`}
              >
                <div className={styles.collapse_item}>
                  <div>FCA-数知鸟</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link
                href={`/detail/${process.env.NEXT_PUBLIC_FCA_DATA_ANALYSIS_CERTID}`}
              >
                <div className={styles.collapse_item}>
                  <div>FCA-数据分析理论</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link
                href={`/detail/${process.env.NEXT_PUBLIC_JIUSHUYUN_CERTID}`}
              >
                <div className={styles.collapse_item}>
                  <div>FCA-九数云</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link
                href={`/detail/${process.env.NEXT_PUBLIC_FINEDATALINK_CERTID}`}
              >
                <div className={styles.collapse_item}>
                  <div>FCA-FineDataLink</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fca_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fca)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className={styles.collapse_wrapper_outer}>
        <div className={styles.collapse_wrapper}>
          <Collapse
            expandIconPosition="right"
            defaultActiveKey={['1']}
            onChange={(data) => {
              console.log(data);
            }}
          >
            <Panel
              header={
                <div>
                  <div className={styles.collapse_header_com}>
                    <div className={styles.collapse_header_title}>资深认证</div>
                    <div className={cx(styles.collapse_header_tag, styles.fcp)}>
                      FCP
                    </div>
                  </div>
                  <p className={styles.collapse_header_subtitle}>
                    掌握企业实际岗位中需要的知识和能力
                  </p>
                  <p className={styles.collapse_header_subtitle}>
                    能够完全胜任相关岗位
                  </p>
                </div>
              }
              key="1"
              className={styles.collapse_header}
            >
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCRP_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCP-报表开发工程师（原FCRP）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fcp_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fcp_arrow)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCRPD_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCP-报表交付工程师（原FCRP-D）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fcp_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fcp_arrow)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>

              {/*<Link*/}
              {/*  href={`/detail/${process.env.NEXT_PUBLIC_FCP_PLUGIN_CERTID}`}*/}
              {/*>*/}
              {/*  <div className={styles.collapse_item}>*/}
              {/*    <div>FCP-FineReport插件开发工程师</div>*/}
              {/*    <Space className={styles.collapse_item_content}>*/}
              {/*      <span className={cx(styles.text, styles.fcp_color)}>*/}
              {/*        详情*/}
              {/*      </span>*/}
              {/*      <div className={cx(styles.icon_wrapper, styles.fcp_arrow)}>*/}
              {/*        <img*/}
              {/*          alt=""*/}
              {/*          src="/img/arrow_right.png"*/}
              {/*          width="12"*/}
              {/*          height="12"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </Space>*/}
              {/*  </div>*/}
              {/*</Link>*/}

              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCJP_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCP-零代码开发工程师（原FCJP）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fcp_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fcp_arrow)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link href={`/detail/${process.env.NEXT_PUBLIC_FCBP6_CERTID}`}>
                <div className={styles.collapse_item}>
                  <div>FCP-FineBI（原FCBP）</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fcp_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fcp_arrow)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
              <Link
                href={`/detail/${process.env.NEXT_PUBLIC_FCP_BUSINESS_DATA_ANALYSIS_CERTID}`}
              >
                <div className={styles.collapse_item}>
                  <div>FCP-商业数据分析师</div>
                  <Space className={styles.collapse_item_content}>
                    <span className={cx(styles.text, styles.fcp_color)}>
                      详情
                    </span>
                    <div className={cx(styles.icon_wrapper, styles.fcp_arrow)}>
                      <img
                        alt=""
                        src="/img/arrow_right.png"
                        width="12"
                        height="12"
                      />
                    </div>
                  </Space>
                </div>
              </Link>
            </Panel>
          </Collapse>
        </div>
      </div>
    </>
  );
};
export default ClassIntroMobile;
