import React from "react";
// eslint-disable-next-line max-len
import ClassIntroMobile from "@/page-components/home/Class_intro/class_intro_mobile";
import ClassIntroPc from "@/page-components/home/Class_intro/class_intro_pc";
import MobileComponentWrapper from "@/components/MobileComponentWrapper";
import PcComponentWrapper from "@/components/PcComponentWrapper";
import styles from './styles.module.less';

const ClassIntro:React.FC = () => {
  return (
    <div className={styles.class_intro_wrapper}>
      <MobileComponentWrapper>
        <ClassIntroMobile />
      </MobileComponentWrapper>
      <PcComponentWrapper>
        <ClassIntroPc />
      </PcComponentWrapper>
    </div>
  )
}
export default ClassIntro;
