import React, {useRef} from 'react';
import {Button, Space, Tooltip} from "antd";
import {useSize} from "ahooks";
import {Link} from "react-scroll";
import PcComponentWrapper from "@/components/PcComponentWrapper";
import {Media} from "@/constants/media_query";
import FastNav from "@/page-components/home/Fast_nav";
import styles from './styles.module.less';

const Intro:React.FC = () => {
  const ref = useRef(null);
  const size = useSize(ref);
  const left = size ? (size.width - 1440 ) / 2 : 0;
  return (
    <>
      <PcComponentWrapper>
        <div className={styles.intro_wrapper} ref={ref}>
          <div className={styles.intro}>
            <div className={styles.intro_inner}>
              <div className={styles.item}>
                <Space>
                  <img src="img/intro_icon1.png" alt="" className={styles.icon_img}/>
                  <div className={styles.title}>行业权威认证</div>
                </Space>
                <p className={styles.subtitle}>帆软处于行业领先，凭借多年行业经验推出的认证同样体现其行业权威性</p>
              </div>
              <div className={styles.item}>
                <Space>
                  <img src="img/intro_icon2.png" alt="" className={styles.icon_img}/>
                  <div className={styles.title}>能力雷达</div>
                </Space>
                <p className={styles.subtitle}>充分展示自我专业知识和技能，充实职业生涯，获得行业认可</p>
              </div>
              <div className={styles.item}>
                <Space>
                  <img src="img/intro_icon3.png" alt="" className={styles.icon_img}/>
                  <div className={styles.title}>与新技术同步</div>
                </Space>
                <p className={styles.subtitle}>通过学习紧跟技术发展趋势，获取最新的技术知识。满足企业的更高用人要求</p>
              </div>
              <div className={styles.item}>
                <Space>
                  <img src="img/intro_icon4.png" alt="" className={styles.icon_img}/>
                  <div className={styles.title}>获取更高薪酬</div>
                </Space>
                <p className={styles.subtitle}>通过认证可以更受雇主们青睐。加入数据人才库后可享受专属高薪岗位推荐服务。</p>
              </div>
            </div>
            <Media greaterThanOrEqual="xl">
              <FastNav left={left} />
            </Media>
          </div>
        </div>
      </PcComponentWrapper>
    </>
  )
}
export default Intro;
