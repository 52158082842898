export enum VerifyEnum {
  FCRA = 1,
  FCRP,
  FCBA,
  FCBP,
  FCBP6,
  FCAA,
  FCAP,
  FCRPD,
  FCJA,
  FCJP,
  FCA_SHUZHINIAO,
  FCP_PLUGIN,
  FCA_DATA_ANALYSIS,
  FCA_JIUSHUYUN,
  FCP_DATA_ANALYSIS,
  FCBA5,
  FINEDATALINK,
  FCP_BUSINESS_DATA_ANALYSIS,
}

export type VerifyPaperData = {
  isPass: boolean;
  paperId: string;
  score: number;
};
export type VerifyData = {
  paperId: string | null;
  certName: string;
  issueDate: number | null; // 发证时间
  entryTime: number; // 报名时间戳
  certnoUrl: string | null;
  cert_no: string | null;
  entryId: string;
  isPass: boolean; // 是否通过
  // papers?:VerifyPaperData[]
};
export type VerifyOtherpeopleData = {
  certnoUrl: string;
  issuanceDate: number;
};
export type TeacherPaperList = {
  certId: string;
  paperId: string;
  realName: string;
  comName: string; // 考生企业名称
  score: number | null;
  scoreTime: number;
  submitTime: number;
  userName: string;
};
